import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';
import { ApiDataService } from './data/api-data.service';

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {
  debounceTime = 1000;
  forbiddenUserNames = ['admin', 'administrator', 'pocketlist'];
  timeoutDuplicateUserName;

  constructor(private apiDataService: ApiDataService) { }

  forbiddenUserName(control: AbstractControl): { [s: string]: boolean } {
    if (this.forbiddenUserNames.indexOf(control.value) !== -1)
      return { 'forbiddenUserName': true };

    return null;
  }

  duplicateUserName(control: AbstractControl): Promise<ValidationErrors | null> {
    clearTimeout(this.timeoutDuplicateUserName);
    return new Promise(resolve => {
      this.timeoutDuplicateUserName = setTimeout(() => {
        this.apiDataService.checkUserName(control.value).then(result => {
          if (result.data) {
            resolve({ duplicateUserName: true });
          } else {
            resolve(null);
          }
        }).catch(error => {
          resolve(null);
        });
      }, this.debounceTime);
    });
  }
}
