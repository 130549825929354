@switch (buttonType) {
  @case('mat-button') {
<button mat-button [routerLink]="routerLink" [routerLinkActive]="routerLink">
  <mat-icon>{{ icon }}</mat-icon>
  <span *ngIf="showName">{{ name }}</span>
</button>
  }
  @case('mat-icon-button') {
<button mat-icon-button [routerLink]="routerLink" [routerLinkActive]="routerLink" [matTooltip]="name">
  <mat-icon>{{ icon }}</mat-icon>
  <span *ngIf="showName">{{ name }}</span>
</button>
  }
  @case('mat-menu-item')
  {
<button mat-menu-item [routerLink]="routerLink" [routerLinkActive]="routerLink" [matTooltip]="name">
  <mat-icon>{{ icon }}</mat-icon>
  <span>{{ name }}</span>
</button>
  }
}
