<div class="p-4 pt-2" *ngIf="manageCatalog$ | async as manageCatalog">
    <form #form="ngForm" [appProgress]="isProcessing$ | async">
        <div class="field">
            <label for="name">Name</label>
            <input type="text" #name="ngModel" id="name" name="name" [(ngModel)]="manageCatalog.name" pInputText
                required class="w-full" />
            <small class="text-red-500">
                <span *ngIf="isRequired(name)">
                    Name is required
                </span>
                <span *ngIf="isMaxLength(name)">
                    Name is too long ({{ getMaxLengthDisplay(name) }} characters)
                </span>
            </small>
        </div>
        <div class="field">
            <label for="description">Description</label>
            <app-editor #editor [(value)]="manageCatalog.description"
                [maxLength]="Validation.DescriptionMaxLength"></app-editor>
            <small class="text-red-500">
                <span *ngIf="editor?.getEditor?.errors?.['maxlength']">
                    Description is too long (max length: {{ getMaxLengthDisplay(editor.getEditor) }} characters)
                </span>
            </small>
        </div>
        <app-manage-items></app-manage-items>
    </form>
</div>
