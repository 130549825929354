import { Component, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { Validation } from '../../../../common/validation.enums';
import { ControlValidationService } from '../../../../services/control-validation.service';
import { FormService } from '../../../../services/form.service';
import { ManageCatalogService } from '../manage-catalog.service';

@Component({
  selector: 'app-manage-catalog-form',
  templateUrl: './manage-catalog-form.component.html',
  styleUrl: './manage-catalog-form.component.css'
})
export class ManageCatalogFormComponent {
  manageCatalog$ = this.manageCatalogService.manageCatalog$;
  isProcessing$ = this.manageCatalogService.isProcessing$;

  @ViewChild('form', { static: false }) form!: NgForm;

  Validation = Validation.ManageCatalog;

  constructor(
    private manageCatalogService: ManageCatalogService,
    private formService: FormService,
    private controlValidationService: ControlValidationService) {
  }

  onMarkFormAsDirty() {
    this.formService.markFormGroupDirty(this.form);
  }

  isRequired(control: NgModel): boolean {
    return this.controlValidationService.isRequired(control);
  }

  isMaxLength(control: NgModel): boolean {
    return this.controlValidationService.isMaxLength(control);
  }

  getMaxLengthDisplay(control: NgModel): number {
    return this.controlValidationService.getMaxLengthDisplay(control);
  }
}
