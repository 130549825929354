export class User {
    id: string;
    userName: string;
    email: string;

    hasCredentials() {
        return this.id?.length > 0
            && this.userName?.length > 0;
    }
}
