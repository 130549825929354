import { Component } from '@angular/core';
import { CatalogService } from '../../../../services/data/catalog.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-catalog-actions',
  templateUrl: './catalog-actions.component.html',
  styleUrl: './catalog-actions.component.scss'
})
export class CatalogActionsComponent {

  constructor(private catalogService: CatalogService,
    private route: ActivatedRoute
  ) {}

  onEdit() {
    this.catalogService.goToEditCatalog(+this.route.snapshot.paramMap.get('catalogId'));
  }
}
