<mat-toolbar [color]="color">
    <div class="flex w-full">
        <div class="flex flex-shrink-0 align-items-center justify-content-start">
            <ng-content select="[left]"></ng-content>
        </div>
        <div class="flex flex-grow-1 align-items-center justify-content-center font-semibold text-xl">
            <ng-content select="[center]"></ng-content>
        </div>
        <div class="flex flex-shrink-0 align-items-center justify-content-end hidden md:flex">
            <ng-content select="[right]"></ng-content>
        </div>
    </div>
</mat-toolbar>
