@if (!loggedIn)
{
<app-button [buttonType]="buttonType" [routerLink]="RouterLinkEnum.Login" [icon]="'login'"
    [showName]="buttonType=='mat-button'" [name]="'Login'">
</app-button>
}
@else
{
<app-button [buttonType]="buttonType" [routerLink]="RouterLinkEnum.Profile" [icon]="'account_circle'"
    [showName]="buttonType=='mat-button'" [name]="'Profile'">
</app-button>

<!-- Desktop -->
<app-button (click)="onLogout()" [buttonType]="buttonType" [routerLink]="RouterLinkEnum.Login" [icon]="'logout'"
    [showName]="buttonType=='mat-icon-button'" [name]="'Logout'" class="flex md:hidden">
</app-button>
<!-- Mobile -->
<app-button (click)="onLogout()" [buttonType]="'mat-icon-button'" [routerLink]="RouterLinkEnum.Login" [icon]="'logout'"
    [showName]="buttonType=='mat-icon-button'" [name]="'Logout'" class="hidden md:flex">
</app-button>
}