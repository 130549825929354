<mat-card class="h-full">
    <mat-card-header>
        <mat-card-title>{{ title }}</mat-card-title>
        <mat-card-subtitle class="h-full">
            <ng-content select="[subtitle]"></ng-content>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-actions class="h-full align-items-end">
        <div class="ml-auto">
            <ng-content select="[actions]"></ng-content>
        </div>
    </mat-card-actions>
</mat-card>