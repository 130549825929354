import { Component, Input } from '@angular/core';
import { Observable, filter, map } from 'rxjs';
import { Item } from '../../../models/api/item.model';
import { ItemService } from '../../../services/data/item.service';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrl: './item.component.css'
})
export class ItemComponent {

  @Input() itemId!: number;
  @Input() catalogId!: number;

  items$: Observable<Item[]> = new Observable<Item[]>;

  item!: Item;

  constructor(private itemService: ItemService) {
    this.items$ = this.itemService.items$;
  }

  async ngOnInit() {
    this.items$.pipe(
      filter(x => x != null),
      map((items: Item[]) => {
        const result = items.find((item: Item) => item.id == this.itemId);
        return result;
      })
    ).subscribe((item: any) => this.item = item);
  }
}
