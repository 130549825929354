import DOMPurify from 'dompurify';

export function createAndAssign<T>(targetType: new () => T, source: any): T {
  if (!source)
    return null;

  const instance = new targetType();
  Object.assign(instance, source);
  return instance;
}

export function createAndAssignArray<T>(targetType: new () => T, source: any): T[] {
  if (!source || !Array.isArray(source))
    return null;

  const items: T[] = [];
  source.forEach((item: T) => items.push(createAndAssign<T>(targetType, item)));

  return items;
}

declare global {
  interface Array<T> {
    joinForToast(separator?: string): string;
  }
}

Array.prototype.joinForToast = function (separator: string = '\n'): string {
  return this.join(separator);
};

export function sanitize(content: string): string {
  return DOMPurify.sanitize(content, {
    ALLOWED_TAGS: ['strong', 'b', 'i', 'u', 'a', 'p', 'ul', 'ol', 'li'],
    ALLOWED_ATTR: ['href']
  });
}
