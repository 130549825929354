import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ManageMode } from '../../../../models/enums/manage-mode.enum';
import { CatalogService } from '../../../../services/data/catalog.service';
import { ToastService } from '../../../../services/toast.service';

@Component({
  selector: 'app-catalogs-actions',
  templateUrl: './catalogs-actions.component.html',
  styleUrl: './catalogs-actions.component.scss'
})
export class CatalogsActionsComponent implements OnDestroy {

  getCatalogsSubscription: Subscription;

  isRefreshing: boolean;
  ManageMode = ManageMode;

  constructor(private catalogService: CatalogService,
    private toastService: ToastService) { }

  ngOnDestroy() {
    this.getCatalogsSubscription?.unsubscribe();
  }

  onManageCatalog(mode: ManageMode, id: number = 0) {
    switch (mode) {
      case (ManageMode.New):
        this.catalogService.goToNewCatalog();
        break;
      case (ManageMode.Edit):
        this.catalogService.goToEditCatalog(id);
        break;

      default:
        this.toastService.showInfo('No Mode Selected');
    }
  }

  onRefresh() {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.getCatalogsSubscription = this.catalogService.getCatalogs()
        .subscribe(() =>
          this.isRefreshing = false
        );
    }
  }
}
