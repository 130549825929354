import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Messages } from '../../../common/messages.enums';
import { RouterLinkEnum } from '../../../models/enums/router-link.enum';
import { UserService } from '../../../services/data/user.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.css'
})
export class MenuComponent {
  loggedIn$ = new Observable<boolean>(null);

  RouterLinkEnum = RouterLinkEnum;

  title = Messages.Information.Title;

  constructor(private userService: UserService) {
    this.loggedIn$ = this.userService.loggedIn$;
  }
}
