import { Injectable } from '@angular/core';
import { ApiDataService } from './api-data.service';

@Injectable({
  providedIn: 'root'
})
export class ExceptionLogService {

  constructor(private apiDataService: ApiDataService) {
  }

  async getLastExceptionLog() {
    return await this.apiDataService.getLastExceptionLog();
  }
}
