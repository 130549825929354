import { Component } from '@angular/core';
import { RouterLinkEnum } from '../../../models/enums/router-link.enum';

@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrl: './description.component.scss'
})
export class DescriptionComponent {
  routerLinkEnum = RouterLinkEnum;
}
