<p-card class="pocketlist">
    <app-page-heading title="Items"></app-page-heading>
    <div class="grid p-4">
    <div class="flex col-12">
      <p-autoComplete #dropdown [(ngModel)]="newItemName" [suggestions]="filteredItems" field="name"
        (onSelect)="onSelectItem($event)" (completeMethod)="onSearchItems($event)" [dropdown]="true"
        placeholder="Enter or Search Item">
        <ng-template let-item pTemplate="item">
          <div class="flex flex-colum">
            <div class="flex-grow-1">{{ item.name }}</div>
            <div class="flex-grow-0">
              <p-button icon="pi pi-plus" size="small" [rounded]="true" [text]="true"
                (onClick)="onAddItemInine(item, $event)" pTooltip="Add Item" class="mx-1" />
            </div>
          </div>
        </ng-template></p-autoComplete>
      <div class="mx-4"></div>
      <div *ngIf="newItemName?.length > 0" class="flex flex-colum">
        <p-button icon="pi pi-plus" (onClick)="onAddCatalogItem(addItemStateValue)"
          [pTooltip]="'Add Item (' + addItemStateValue + ')'" tooltipPosition="top" class="mx-1" />
        <p-selectButton [options]="addItemStateOptions" [(ngModel)]="addItemStateValue" optionLabel="label"
          optionValue="value" />
      </div>
      <div class="flex-grow-1"></div>
      <div>
        <p-button icon="pi pi-plus" (onClick)="onEditItem(null)" pTooltip="Add Item" class="mx-1" />
      </div>
    </div>
        <div class="col-12">
            <div *ngIf="targetItems?.length > 0; else noItems" class="grid">
        <div class="col-12">
          <p-table [value]="targetItems">
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="nowrap">Name</th>
                            <th>Description</th>
                <th class="actions"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-item>
                        <tr>
                            <td class="nowrap font-bold">{{ item.name }}</td>
                            <td>
                                <div>
                    <app-show-more [text]="item.description" clampLines="1" [inline]="true"></app-show-more>
                                </div>
                            </td>
                            <td class="actions">
                                <span class="button-placeholder">
                    <p-button (click)="onEditItem(item)" [ngClass]="{'v-hidden': item.id > 0}" pTooltip="Edit Item"
                      class="mx-1" icon="pi pi-pencil" size="small" [rounded]="false" [text]="true" />
                                </span>
                                <span class="button-placeholder">
                    <p-button (click)="onRemoveCatalogItem(item)" class="mx-1" pTooltip="Remove Item" severity="danger"
                      icon="pi pi-minus" size="small" [rounded]="false" [text]="true" />
                                </span>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
      </div>
            <ng-template #noItems>
        <div class="col-12 mb-2">
          No Items
                </div>
            </ng-template>
        </div>
        </div>
</p-card>

<p-dialog header="Edit Item" [(visible)]="showEdiItem" [style]="DialogStyles.style"
    [breakpoints]="DialogStyles.breakpoints" [modal]="true">
    <form #form="ngForm" *ngIf="editItem">
        <!-- [appProgress]="isProcessing$ | async" [containerBackground]="true" -->
        <div class="field">
            <label for="name">Name</label>
            <input type="text" #name="ngModel" id="name" name="name" [(ngModel)]="editItem.name" pInputText required
                class="w-full" />
            <!-- <small class="text-red-500">
            <span *ngIf="isRequired(name)">
                Name is required
            </span>
            <span *ngIf="isMaxLength(name)">
                Name is too long ({{ getMaxLengthDisplay(name) }} characters)
            </span>
        </small> -->
        </div>
        <div class="field">
            <label for="description">Description</label>
      <app-editor #editor [(value)]="editItem.description" [maxLength]="Validation.DescriptionMaxLength"></app-editor>
      <small class="text-red-500">
            <span *ngIf="editor?.getEditor?.errors?.['maxlength']">
                Description is too long (max length: {{ getMaxLengthDisplay(editor.getEditor) }} characters)
            </span>
      </small>
        </div>
    </form>
    <ng-template pTemplate="footer">
        <p-button label="Cancel" [text]="true" severity="secondary" (onClick)="onCancelUpdateItem()" />
    <p-button [label]="currentEditItem ? 'Save' : 'Add'" [outlined]="true" severity="secondary"
      (onClick)="onUpdateItem()" />
    </ng-template>
</p-dialog>
