import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CatalogComponent } from './components/catalogs/catalog/catalog.component';
import { CatalogsComponent } from './components/catalogs/catalogs/catalogs.component';
import { ManageCatalogComponent } from './components/catalogs/manage-catalog/manage-catalog.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { SelectEntityComponent } from './components/display/select-entity/select-entity.component';
import { ItemsComponent } from './components/items/items/items.component';
import { LoginRegisterComponent } from './components/user/login-register/login-register.component';
import { ProfileComponent } from './components/user/profile/profile.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { UserGuard } from './guards/user-guard.service';
import { RouterLinkEnum } from './models/enums/router-link.enum';

const routes: Routes = [
  {
    path: '', component: WelcomeComponent,
    pathMatch: 'full'
  },
  {
    path: RouterLinkEnum.Login,
    component: LoginRegisterComponent
  },
  {
    path: RouterLinkEnum.Profile, canActivate: [UserGuard],
    component: ProfileComponent
  },

  {
    path: `${RouterLinkEnum.Catalogs}`,
    component: CatalogsComponent,
    children:
    [
      {
        path: '',
        component: SelectEntityComponent,
        pathMatch: 'full'
      },
      {
        path: `:catalogId`,
        component: CatalogComponent
      },
      {
        path: ':catalogId/:action',
        component: ManageCatalogComponent
      }
    ]
  },
  {
    path: RouterLinkEnum.Items,
    component: ItemsComponent
  },
  {
    path: RouterLinkEnum.Categories,
    component: CategoriesComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
