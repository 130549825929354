import { Directive, Input, ViewContainerRef, ComponentRef } from '@angular/core';
import { ProgressComponent } from '../components/display/progress/progress.component';

@Directive({
  selector: '[appProgress]'
})
export class ProgressDirective {
  @Input() appProgress = false;
  @Input() relative = true;
  @Input() fadeDuration = 500;
  @Input() fadeStep = 50;
  @Input() text = '';
  @Input() containerBackground: boolean = true;
  @Input() innerBorder: boolean = false;

  parentClass = 'relative';

  private containerDiv: HTMLDivElement;
  private componentRef: ComponentRef<ProgressComponent>;

  constructor(private viewContainerRef: ViewContainerRef
  ) { }

  ngOnChanges() {
    if (this.appProgress) {
      this.renderProgress();
    } else {
      this.removeProgress();
    }
  }

  private renderProgress() {
    if (!this.componentRef) {
      this.componentRef = this.viewContainerRef.createComponent(ProgressComponent);

      this.componentRef.instance.text = this.text;
      this.componentRef.instance.containerBackground = this.containerBackground;
      this.componentRef.instance.innerBorder = this.innerBorder;

      this.containerDiv = document.createElement('div');
      this.containerDiv.appendChild(this.componentRef.location.nativeElement);

      //TODO: check if it had class, then flag to NOT remove when destroy, etc
      if (this.relative)
        this.viewContainerRef.element.nativeElement.classList.add(this.parentClass);

      let parentNode = this.viewContainerRef.element.nativeElement;
      const firstChild = parentNode.firstChild;
      if (firstChild) {
        parentNode.insertBefore(this.containerDiv, firstChild);
      } else {
        parentNode.appendChild(this.containerDiv);
      }
    }
  }

  private removeProgress() {
    if (this.componentRef && this.containerDiv) {
      this.containerDiv.style.opacity = '1';
      this.containerDiv.style.zIndex = '-1';

      if (this.fadeDuration <= 0) {
        this.remove();
      } else {
        const fadeDuration = this.fadeDuration > 0 ? this.fadeDuration : 1;
        const fadeStep = this.fadeStep;
        const opacityStep = parseFloat(this.containerDiv.style.opacity) / (fadeDuration / fadeStep);
        const fadeOut = () => {
          this.containerDiv.style.opacity = (parseFloat(this.containerDiv.style.opacity) - opacityStep).toString();
          if (parseFloat(this.containerDiv.style.opacity) > 0) {
            setTimeout(fadeOut, fadeStep);
          } else {
            this.remove();
          }
        };
        fadeOut();
      }
    }
  }

  private remove() {
    if (this.relative)
      this.viewContainerRef.element.nativeElement.classList.remove(this.parentClass);
    
    this.componentRef?.destroy();
    this.containerDiv.remove();
    this.componentRef = undefined;
  }
}
