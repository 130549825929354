import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-show-more',
  templateUrl: './show-more.component.html',
  styleUrl: './show-more.component.scss'
})
export class ShowMoreComponent implements AfterViewInit, OnChanges {
  @Input() text: string = null;
  @Input() isExpanded: boolean;
  @Input() clampLines = '5';
  @Input() inline: boolean = false;
  @Input() stickyTop: boolean = true;
  @Input() stickyPadding: number = 0;
  

  @Output() isExpandedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('heightDiv', { static: true }) heightDiv: ElementRef;

  exceedsOneLine: boolean = null;

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      if (changes['text'])
        this.checkHeight();
    }, 0);
  }

  ngAfterViewInit(): void {
    this.checkHeight();
    this.cdr.detectChanges();
  }

  get clampLinesClass() {
    return `clamp-${this.clampLines}`;
  }

  getClassObject() {
    return {
      'flex-grow-1': this.inline,
      [this.clampLinesClass]: true
    };
  }

  toggleShow() {
    this.isExpanded = !this.isExpanded;
    this.isExpandedChange.emit(this.isExpanded);
  }

  checkHeight() {
    const element = this.heightDiv.nativeElement;
    const initialHeight = element.clientHeight;

    const initialwebkitLineClamp = element.style.webkitLineClamp;
    const initialMaxHeight = element.style.maxHeight;

    // Temporarily remove the line clamp to measure full height
    element.style.webkitLineClamp = 'unset';
    element.style.maxHeight = 'none';
    const fullHeight = element.clientHeight;

    // Restore the line clamp
    element.style.webkitLineClamp = initialwebkitLineClamp;
    element.style.maxHeight = initialMaxHeight;

    // Emit whether the text exceeds one line
    this.exceedsOneLine = fullHeight > initialHeight;
  }
}
