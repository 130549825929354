<app-button [buttonType]="'mat-icon-button'" [routerLink]="RouterLinkEnum.Home" [icon]="'home'"
    [showName]="buttonType=='mat-button'" [name]="'Home'">
</app-button>
@if (loggedIn)
{
<app-button [buttonType]="buttonType" [routerLink]="RouterLinkEnum.Catalogs" [icon]="'library_books'"
    [showName]="buttonType=='mat-button'" [name]="'Catalogs'">
</app-button>
<app-button [buttonType]="buttonType" [routerLink]="RouterLinkEnum.Items" [icon]="'list_alt'"
    [showName]="buttonType=='mat-button'" [name]="'Items'">
</app-button>
<app-button [buttonType]="buttonType" [routerLink]="RouterLinkEnum.Categories" [icon]="'outlined_flag'"
    [showName]="buttonType=='mat-button'" [name]="'Catagories'">
</app-button>
}
