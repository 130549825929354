import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { ValidatorService } from '../../services/validator.service';

@Directive({
  selector: '[appForbiddenUserName]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: ForbiddenUserNameValidatorDirective, multi: true }
  ]
})
export class ForbiddenUserNameValidatorDirective implements Validator {
  constructor(private validatorService: ValidatorService) {}

  validate(control: AbstractControl): ValidationErrors | null {
    return this.validatorService.forbiddenUserName(control);
  }
}
