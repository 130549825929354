import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CatalogItem } from '../models/api/catalog-item.model';
import { Catalog } from '../models/api/catalog.model';
import { Category } from '../models/api/category.model';
import { Item } from '../models/api/item.model';
import { User } from '../models/user/user.model';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  private statesFactory: { [key: string]: BehaviorSubject<any> } = {};

  private _userSubject: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  user$: Observable<User> = this._userSubject.asObservable();

  private _catalogsSubject: BehaviorSubject<Array<Catalog>> = new BehaviorSubject<Array<Catalog>>(null);
  catalogs$: Observable<Array<Catalog>> = this._catalogsSubject.asObservable();

  private _catalogSubject: BehaviorSubject<Catalog> = new BehaviorSubject<Catalog>(null);
  catalog$: Observable<Catalog> = this._catalogSubject.asObservable();

  private _catalogItems: BehaviorSubject<Array<CatalogItem>> = new BehaviorSubject<Array<CatalogItem>>(null);
  catalogItems$: Observable<Array<CatalogItem>> = this._catalogItems.asObservable();

  private _itemsSubject: BehaviorSubject<Item[]> = new BehaviorSubject<Item[]>(null);
  items$: Observable<Item[]> = this._itemsSubject.asObservable();

  private _categoriesSubject: BehaviorSubject<Category[]> = new BehaviorSubject<Category[]>([]);
  categories$: Observable<Category[]> = this._categoriesSubject.asObservable();

  constructor() { }

  updateUserSubject(user: User) {
    this._userSubject.next(user);
  }

  updateCatalogsSubject(catalogs: Array<Catalog>) {
    this._catalogsSubject.next(catalogs);
  }

  updateCatalogSubject(catalog: Catalog) {
    this._catalogSubject.next(catalog);
  }

  updateCatalogItemsSubject(catalogItems: Array<CatalogItem>) {
    this._catalogItems.next(catalogItems);
  }

  updateItemsSubject(items: Item[]) {
    this._itemsSubject.next(items);
  }

  updateCategoriesSubject(categories: Category[]) {
    this._categoriesSubject.next(categories);
  }

  getState(key: string, defaultValue: any = null): Observable<any> {
    if (!this.statesFactory[key]) {
      this.statesFactory[key] = new BehaviorSubject<any>(defaultValue);
    }
    return this.statesFactory[key].asObservable();
  }

  getValue(key: string, defaultValue: any = null) {
    if (!this.statesFactory[key]) {
      this.statesFactory[key] = new BehaviorSubject<any>(defaultValue);
    }
    return this.statesFactory[key].getValue();
  }

  setState(key: string, value: any): void {
    if (!this.statesFactory[key]) {
      this.statesFactory[key] = new BehaviorSubject<any>(value);
    }
    this.statesFactory[key].next(value);
  }
}
