<mat-toolbar color="primary">
  <div class="flex w-full">

    <!-- Left -->
    <div class="flex align-items-center justify-content-start col">

      <!-- Desktop -->
      <app-pages [loggedIn]="loggedIn$ | async" [buttonType]="'mat-button'" class="align-items-center hidden md:flex"></app-pages>

      <!-- Mobile -->
      <app-pages [loggedIn]="loggedIn$ | async" [buttonType]="'mat-icon-button'" class="flex md:hidden"></app-pages>
    </div>

    <!-- Center -->
    <div class="flex-shrink-0 align-items-center justify-content-center hidden md:flex font-semibold text-xl">
      {{ title }}
    </div>

    <!-- Right Desktop -->
    <div class="flex-grow-1 flex-shrink-1 align-items-center justify-content-end col hidden md:flex">
      <app-settings [loggedIn]="loggedIn$ | async" class="align-items-center flex flex-row"></app-settings>
    </div>

    <!-- Right Mobile -->
    <div class="flex-grow-1 flex-shrink-1 align-items-center justify-content-end col flex md:hidden">
      <button mat-icon-button [matMenuTriggerFor]="menuSettings" #menuTrigger="matMenuTrigger" xPosition="before">
        <mat-icon>settings</mat-icon>
      </button>
      <mat-menu #menuSettings="matMenu" [overlapTrigger]="false">
        <app-settings [loggedIn]="loggedIn$ | async" [buttonType]="'mat-menu-item'"></app-settings>
      </mat-menu>

    </div>
  </div>
</mat-toolbar>
