import { ChangeDetectorRef, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { AutoComplete } from 'primeng/autocomplete';
import { Dropdown } from 'primeng/dropdown';
import { Subject, filter, first, takeUntil } from 'rxjs';
import { Styles } from '../../../common/style.enum';
import { Validation } from '../../../common/validation.enums';
import { Item } from '../../../models/api/item.model';
import { ManageItems } from '../../../models/api/manage-items.model';
import { ControlValidationService } from '../../../services/control-validation.service';
import { ItemService } from '../../../services/data/item.service';
import { ToastService } from '../../../services/toast.service';
import { ManageItemsService } from './manage-items.service';

@Component({
  selector: 'app-manage-items',
  templateUrl: './manage-items.component.html',
  styleUrl: './manage-items.component.scss'
})
export class ManageItemsComponent implements OnDestroy {
  @Input() catalogId: number;

  manageItems$ = this.manageItemsService.manageItems$;
  itemsIsProcessing$ = this.manageItemsService.isProcessing$;

  private itemsSub$ = new Subject<void>();

  items: Item[] = [];

  sourceItems: any[] = [];
  targetItems: any[] = [];

  filteredItems: string[] = [];
  newItemName: string;

  showEdiItem: boolean = false;
  currentEditItem: Item;
  editItem: Item;

  addItemStateOptions: any[] = [
    { label: 'Inline', value: 'inline' },
    { label: 'Popup', value: 'popup' }
  ];

  addItemStateValue = 'inline';

  Validation = Validation.ManageItem;
  DialogStyles = Styles.Dialog;

  constructor(private itemService: ItemService,
    private manageItemsService: ManageItemsService,
    private controlValidationService: ControlValidationService,
    private toastService: ToastService,
    private cdr: ChangeDetectorRef
  ) {
    this.manageItemsService.manageItems$.pipe(
      filter((manageItems: ManageItems) => manageItems != null),
      first()
    ).subscribe(() => this.subscribeToItems());
  }

  ngOnDestroy(): void {
    this.itemsSub$.unsubscribe();
  }

  subscribeToItems() {
    this.itemService.items$
      .pipe(
        first(items => items != null),
        takeUntil(this.itemsSub$))
      .subscribe((items: Item[]) => {
        this.items = items;
        this.filterSelection();
      });
  }

  filterSelection() {
    this.targetItems = this.items
      .filter(item => this.manageItemsService.manageItems.itemIds.includes(item.id));
    this.sourceItems = this.items
      .filter(item => !this.manageItemsService.manageItems.itemIds.includes(item.id));
  }

  onSearchItems(event: any) {
    const query = event.query;
    this.filteredItems = [...this.sourceItems
      .filter(item => item.name.toLowerCase().includes(query.toLowerCase()))];
  }

  onSelectItem(event) {
    this.addItem(event.value);
  }

  onAddItemInine(selectedItem: Item, event = null) {
    this.addItem(selectedItem, event);
  }

  addItem(selectedItem: Item, event = null) {
    this.addItemToCatalog(selectedItem);
    this.manageItemsService.removeItem(this.sourceItems, selectedItem);

    this.filterSelection();

    if (event) {
      event.stopPropagation();
    } else {
    this.resetNewItem();
  }

    this.onSearchItems({ query: this.newItemName ?? '' });
    this.cdr.detectChanges();
  }

  onRemoveCatalogItem(itemToRemove: Item) {
    this.removeItemFromCatalog(itemToRemove);

    if (itemToRemove.id !== 0) {
      this.sourceItems.push(itemToRemove);
    }

    this.cdr.detectChanges();
  }

  /** Adds Catalog Item to Catalog */
  onAddCatalogItem(addType: string) {
    switch (addType) {
      case 'inline':
        this.addCatalogItem(addType, this.newItemName);
        break;
      case 'popup':
        this.editItem = new Item;
        this.currentEditItem = null;
        this.editItem.name = this.newItemName;
        this.showEdiItem = true;
        break;
    }
  }

  addCatalogItem(addType: string, item: string | Item) {
    const itemName = addType == 'inline' ? item as string : (item as Item).name;

    const existingTargetItem = this.targetItems.find((item: Item) => item.name == itemName);
    const existingItem = this.sourceItems.find((item: Item) => item.name == itemName);

    if (existingTargetItem) {
      this.toastService.showInfo('Item Name already added');
      return;
    } else if (existingItem) {
      this.addItemToCatalog(existingItem);
      this.manageItemsService.removeItem(this.sourceItems, existingItem);
      this.toastService.showSuccess('Item added (from existing list)');
      this.cdr.detectChanges();
    } else {

      let newItem;
      switch (addType) {
        case 'inline':
          newItem = new Item;
      newItem.name = this.newItemName;
          
          break;
        case 'popup':
          newItem = (item as Item);
          this.currentEditItem = null;
          this.showEdiItem = false;
          break;
      }

      this.addItemToCatalog(newItem);

      this.resetNewItem();
      this.resetEditItem();
      this.cdr.detectChanges();
    }
  }

  onEditItem(item: Item) {
    this.showEdiItem = true;
    this.currentEditItem = item;
    this.editItem = { ...item };
  }

  onUpdateItem() {
    if (this.currentEditItem) {
    if (this.updateItemToCatalog()) {
      this.cdr.detectChanges();
    } else {
      this.toastService.showError('Item not found and was not updated.');
    }
    this.resetEditItem();
    } else {
      this.addCatalogItem('popup', this.editItem);
  }
  }

  onCancelUpdateItem() {
    this.resetEditItem();
  }

  addItemToCatalog(itemToAdd: Item) {
    this.targetItems.push(itemToAdd);
    this.manageItemsService.addItemToManageItems(itemToAdd);
    }

  updateItemToCatalog(): boolean {
    const newItemsIndex = this.manageItemsService.manageItems.items.findIndex((item: Item) => item == this.currentEditItem);
    if (newItemsIndex !== -1) {
      this.manageItemsService.manageItems.items[newItemsIndex] = this.editItem;
      this.cdr.detectChanges();
    }

    const existingItemIndex = this.targetItems.findIndex((item: Item) => item == this.currentEditItem);
    if (existingItemIndex !== -1) {
      this.targetItems[existingItemIndex] = this.editItem;
      return true;
    }

    return false;
  }

  removeItemFromCatalog(itemToRemove: Item) {
    this.manageItemsService.removeItem(this.targetItems, itemToRemove);
    this.manageItemsService.removeItemFromManageItems(itemToRemove);
  }

  resetEditItem() {
    this.showEdiItem = false;
    this.currentEditItem = null;
    this.editItem = null;
  }

  resetNewItem() {
    this.newItemName = '';
    setTimeout(() => this.newItemName = '');
  }

  getMaxLengthDisplay(control: NgModel): number {
    return this.controlValidationService.getMaxLengthDisplay(control);
  }
}
