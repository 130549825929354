export class ErrorData {
    statusCode!: number;
    statusCodeDescription!: string;
    
    message!: string;
    validationMessages!: string[];

    endPoint!: string;
    entityData!: string;

    isReported: boolean;
}