<div [ngClass]="{ 'flex': inline }">
    <div #heightDiv [innerHTML]="text" class="text-container" [class.expanded]="isExpanded"
        [ngClass]="getClassObject()">
    </div>
    <div appStickyContent [stickyTop]="stickyTop" [stickyOffset]="stickyPadding" *ngIf="exceedsOneLine"
        [ngClass]="{ 'flex-grow-0': inline, 'flex flex-grow-0 justify-content-center': !inline, 'align-content-end': !stickyTop }">
        <div #stickyContent>
        <p-button (click)="toggleShow()" [pTooltip]="isExpanded ? 'Show less' : 'Show more'"
            [icon]="isExpanded ? 'pi pi-angle-double-up' : 'pi pi-angle-double-down'" size="small" [rounded]="true"
            [text]="true"></p-button>
    </div>
</div>
</div>
