<div *ngIf="value" class="py-2">
    <p-card [header]="header ?? ''">
        <ng-content></ng-content>
        <div *ngIf="fullHeight">
            <pre><code class="text-xs">{{ value | json }}</code></pre>
        </div>
        <p-scrollPanel *ngIf="!fullHeight" [style]="{ width: '100%', height: '200px' }">
            <pre><code class="text-xs">{{ value | json }}</code></pre>
        </p-scrollPanel>
    </p-card>
</div>
