import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrl: './progress.component.scss'
})
export class ProgressComponent {
  @Input() text: string = '';
  @Input() containerBackground: boolean = false;
  @Input() innerBorder: boolean = false;
}
