import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ManageMode } from '../../../models/enums/manage-mode.enum';
import { ManageItemsService } from '../../items/manage-items/manage-items.service';
import { ManageCatalogService } from './manage-catalog.service';

@Component({
  selector: 'app-manage-catalog',
  templateUrl: './manage-catalog.component.html',
  styleUrl: './manage-catalog.component.scss',
  providers: [ManageCatalogService, ManageItemsService]
})
export class ManageCatalogComponent implements OnChanges {
  @Input() showManageCatalogDialog: boolean;

  @Output() showManageCatalogDialogChange = new EventEmitter<boolean>();

  showManageCatalogDialog$ = this.manageCatalogService.showManageCatalogDialog$;
  manageMode$ = this.manageCatalogService.manageMode$;

  routeSubscription: Subscription;

  constructor(private manageCatalogService: ManageCatalogService,
    private manageItemsService: ManageItemsService,
    private route: ActivatedRoute
  ) {
    this.routeSubscription = this.route.paramMap
      .subscribe(async (paramsMap) => {
        const id = +paramsMap.get('catalogId');
        const manageMode = paramsMap.get('action') as ManageMode;
        
        this.manageCatalogService.initManageCatalog(manageMode, id);
        this.manageItemsService.initManageItems(id);
      });

    this.showManageCatalogDialog$.subscribe((value) => {
      this.showManageCatalogDialog = value;
      this.showManageCatalogDialogChange.emit(value);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('showManageCatalogDialog' in changes) {
      this.manageCatalogService.updateShowManageCatalog(this.showManageCatalogDialog);
      this.showManageCatalogDialogChange.emit(this.showManageCatalogDialog);
    }
  }
}
