<div class="flex flex-1 flex-wrap">
    <div class="col-12" *ngIf="user$ | async as user">
        <p class="text-lg">Welcome {{ user.userName }}!</p>
        <app-description></app-description>
    </div>
    <div class="col-12" *ngIf="!(loggedIn$ | async)">
        <h2 class="font-bold">Welcome to {{ welcomeToTitle }}</h2>
        <app-description></app-description>
        <app-login-register></app-login-register>
    </div>
</div>