import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScreenSizeService {
  private breakpoints = {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
  };

  private currentSize = new BehaviorSubject<string>(this.getScreenSize(window.innerWidth));
  screenSize$ = this.currentSize.asObservable();

  constructor() {
    window.addEventListener('resize', this.onResize.bind(this));
  }

  private getScreenSize(width: number): string {
    if (width >= this.breakpoints.xl) {
      return 'xl';
    } else if (width >= this.breakpoints.lg) {
      return 'lg';
    } else if (width >= this.breakpoints.md) {
      return 'md';
    } else if (width >= this.breakpoints.sm) {
      return 'sm';
    } else {
      return 'xs';
    }
  }

  private onResize(event: Event) {
    const width = (event.target as Window).innerWidth;
    const newSize = this.getScreenSize(width);
    this.currentSize.next(newSize);
  }
}
