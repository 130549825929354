<div *ngIf="itemIds" styleClass="my-2">
    <div *ngIf="itemIds.length > 0; else noItems">
        <div class="flex align-items-center pb-2">
            <div class="flex-grow-1 font-bold text-lg">Items</div>
            <div class="flex-grow-0">
                <div class="flex align-items-center">
                    <ng-container *ngIf="selectableRowOptions.length > 1">
                        <small class="pr-1 hidden sm:flex">Rows:</small>
                        <p-selectButton [options]="selectableRowOptions" [(ngModel)]="rowValue" (onOptionClick)="onRowsClicked($event)"
                            optionLabel="label" optionValue="value" [unselectable]="false" [allowEmpty]="false" class="small-buttons" />
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="grid md:m-2 xs:m-0">
            <p-card *ngFor="let itemId of itemIds" [ngClass]="itemRowClass">
                {{itemId}}
                <app-item [itemId]="itemId" [catalogId]="catalogId"></app-item>
            </p-card>
        </div>
    </div>
    <ng-template #noItems>
        <div class="font-bold text-lg">No Items</div>
    </ng-template>
</div>