import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-select-entity',
  templateUrl: './select-entity.component.html',
  styleUrl: './select-entity.component.scss'
})
export class SelectEntityComponent {
  @Input() title = '';
}
