export enum RouterLinkEnum {
    Home = '',
    Login = 'login',
    Profile = 'profile', 

    Catalogs = 'catalogs',
    Items = 'items',
    Categories = 'categories',

    New = 'new',
    Edit = 'edit'
  }