import { Injectable } from '@angular/core';
import { StorageKeys } from '../common/storage-keys.enums';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  keys = StorageKeys;

  constructor() { }

  setJwt(token: string) {
    this.setItem(this.keys.JwtToken, token);
  }

  getJwt(): string {
    return this.getItem(this.keys.JwtToken); 0
  }

  setItem(key: string, value: any) {
    if (value) {
      localStorage.setItem(key, value);
    } else {
      localStorage.removeItem(key);
    }
  }

  getItem(key: string, defaultValue: any = null) {
    return localStorage.getItem(key) ?? defaultValue;
  }
}
