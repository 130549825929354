<div class="h-full flex-grow-1" [appProgress]="(isProcessing$ | async) && (isSuccessful$ | async) == undefined"
    [text]="loadingText">
    <div *ngIf="catalogs$ | async as catalogs" class="flex flex-column h-full flex-grow-1">
        <app-catalogs-actions></app-catalogs-actions>
        <div class="h-full" *ngIf="catalogs$ | async as catalogs">
            <div class="flex flex-row h-full flex-grow-1">
                <div [ngClass]="{ 
                        'col-6 md:col-6 lg:col-4 xl:col-3' : drawerState == DrawerStateEnum.Side, 
                        'hidden' : drawerState == DrawerStateEnum.Closed,
                        'col-12': drawerState == DrawerStateEnum.Full
                    }" class="px-2 h-full relative">
                    <div class="flex justify-content-end z-5 draw-button-drawer sticky" [style.top.px]="3">
                        <div class="absolute">
                            <button mat-mini-fab color="primary"
                                (click)="onToggleDrawer(drawerState == DrawerStateEnum.Full ? DrawerStateEnum.Side : DrawerStateEnum.Closed)"
                                [class.hidden]="drawerState == DrawerStateEnum.Closed">
                                <mat-icon>keyboard_double_arrow_left</mat-icon>
                            </button>
                        </div>
                    </div>

                    <app-list [type]="ListDisplayType.ScrollTable" [values]="catalogs" [activeLinkId]="activeLinkId"
                        [appProgress]="(isProcessing$ | async) || isRefreshing" [containerBackground]="false"
                        (onValueClicked)="onSelectCatalog($event)" (onValueDeleted)="onDeleteCatalog($event)">

                        <div header>Catalog list</div>
                        <div footer>
                            <div class="text-center">Catalogs (<span class="font-bold">{{ catalogs.length }}</span>)
                            </div>
                        </div>
                    </app-list>
                </div>
                <div class="h-full col-6 md:col-6 lg:col-8 xl:col-9 flex-grow-1 relative">
                    <div class="z-4 draw-button-content sticky" [style.top.px]="10">
                        <div class="absolute">
                            <button mat-mini-fab color="primary"
                                (click)="onToggleDrawer(drawerState == DrawerStateEnum.Closed ? DrawerStateEnum.Side : DrawerStateEnum.Full)"
                                [class.hidden]="drawerState == DrawerStateEnum.Full">
                                <mat-icon>keyboard_double_arrow_right</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="h-full" [ngClass]="{ 
                        'catalog-content-drawer-closed' : drawerState == DrawerStateEnum.Closed,
                        'catalog-content-drawer-side -FIX_ME' : drawerState == DrawerStateEnum.Side,
                        'hidden' : drawerState == DrawerStateEnum.Full 
                        }">
                        <router-outlet></router-outlet>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!(isSuccessful$ | async) && !(isProcessing$ | async)"
        class="h-full w-full flex justify-content-center items-center pt-5">
        <div class="font-bold text-xl">
            Error, please refresh or try again later.</div>
    </div>

</div>

<app-manage-catalog *ngIf="showManageCatalogDialog"
    [(showManageCatalogDialog)]="showManageCatalogDialog"></app-manage-catalog>