import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ManageCatalog } from '../../../models/api/manage-catalog.model';
import { ManageMode } from '../../../models/enums/manage-mode.enum';
import { StateType } from '../../../models/enums/state-type.enum';
import { CatalogService } from '../../../services/data/catalog.service';
import { ErrorService } from '../../../services/error.service';
import { StateService } from '../../../services/state.service';
import { ToastService } from '../../../services/toast.service';
import { createAndAssign } from '../../../utility/common.utils';
import { ManageItemsService } from '../../items/manage-items/manage-items.service';

@Injectable()
export class ManageCatalogService {
  private _manageCatalogSubject: BehaviorSubject<ManageCatalog> = new BehaviorSubject<ManageCatalog>(null);
  manageCatalog$: Observable<ManageCatalog> = this._manageCatalogSubject.asObservable();

  private _isProcessingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isProcessing$: Observable<boolean> = this._isProcessingSubject.asObservable();

  private _showManageCatalogDialogSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showManageCatalogDialog$: Observable<boolean> = this._showManageCatalogDialogSubject.asObservable();

  manageMode$: Observable<boolean> = this.stateService.getState(`${StateType.Catalog}${StateType.ManageMode}`);

  catalogId: number = 0;

  constructor(private catalogService: CatalogService,
    private manageItemsService: ManageItemsService,
    private errorService: ErrorService,
    private stateService: StateService,
    private toastService: ToastService
  ) {
  }

  /** Creates Manage Catalog Behavor Subject */
  initManageCatalog(manageMode: ManageMode, catalogId: number = 0) {
    this.setManageModeState(manageMode);

    this.catalogId = catalogId;

    switch (manageMode) {
      case (ManageMode.New):
        this.newCatalog();
        break;

      case (ManageMode.Edit):
        this.editCatalog(catalogId);
        break;

      default:
        break;
    }
  }

  /** Create new Catalog */
  newCatalog() {
    const newCatalog = new ManageCatalog;
    this._manageCatalogSubject.next(newCatalog);
  }

  /** Creates edit Catalog from provided catalogId */
  async editCatalog(catalogId: number) {
    let editCatalog: ManageCatalog;

    const catalog = await this.catalogService.getCatalog(catalogId);
    if (catalog) {
      editCatalog = createAndAssign(ManageCatalog, catalog);
    }

    this._manageCatalogSubject.next(editCatalog);
  }

  /** Validates then Creates or Updates Catalog */
  async manageCatalog() {
    this.updateIsProcessing(true);

    try {
      if (!this.getManageCatalog.valid()) {
        this.errorService.handleError('Catalog details are invalid', 'Validation error');
        return;
      }

      const catalogId = await this.catalogService.manageCatalog(this.manageModeValue, this.catalogId, this.getManageCatalog);
      
      if (catalogId > 0) {
        const isSuccessful = await this.manageItemsService.manageCatalogItems(catalogId);

        if (isSuccessful) {
          this.toastService.showSuccess(`Catalog ${this.manageModeValue == ManageMode.New ? 'added' : 'edited'}`);
          this.catalogService.goToCatalog(catalogId);
        } else {
          this.toastService.showError('Catalog Items failed to update');
        }
      } else {
        this.toastService.showError('Catalog failed to update');
      }
    } finally {
      this.updateIsProcessing(false);
    }
  }

  async cancelManageCatalog() {
    if (this.isProcessing)
      this.toastService.showWarn('Saving in progress');
    else

      if (this._showManageCatalogDialogSubject.value)
        this.updateShowManageCatalog(false);
      else if (this.catalogId)
        this.catalogService.goToCatalog(this.catalogId);
      else
        this.catalogService.goToCatalogs();
  }

  updateShowManageCatalog(value: boolean) {
    this._showManageCatalogDialogSubject.next(value);
  }

  //TODO: Fix all below and unify

  get isProcessing() {
    return this._isProcessingSubject.getValue();
  }

  updateIsProcessing(value: boolean) {
    this._isProcessingSubject.next(value);
  }

  get getManageCatalog() {
    return this._manageCatalogSubject.getValue();
  }

  get manageModeValue() {
    return this.stateService.getValue(`${StateType.Catalog}${StateType.ManageMode}`);
  }

  setManageModeState(manageMode: ManageMode) {
    this.stateService.setState(`${StateType.Catalog}${StateType.ManageMode}`, manageMode);
  }
}
