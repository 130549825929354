import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss'
})
export class ButtonComponent implements OnInit {
  @Input() buttonType = 'mat-button';
  @Input() routerLink = null;
  @Input() icon = '';
  @Input() showName: boolean;
  @Input() name = '';

  ngOnInit(): void {
    this.showName = this.buttonType == 'mat-button';
  }
}
