import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Messages } from './common/messages.enums';
import { CustomResponse } from './models/api/error/custom-response.model';
import { ApiDataService } from './services/data/api-data.service';
import { ExceptionLogService } from './services/data/exception-log.service';
import { UserService } from './services/data/user.service';
import { ProgressService } from './services/progress.service';
import { ToastService } from './services/toast.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
  providers: [MessageService, ConfirmationService]
})
export class AppComponent implements OnInit {

  title = Messages.Information.Title;
  ready: boolean = false;
  isWaiting: boolean = undefined;

  lastExceptionLog: any;

  constructor(private toastService: ToastService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private progressService: ProgressService,
    private apiDataService: ApiDataService,
    private userService: UserService,
    private exceptionLogService: ExceptionLogService) {
    this.toastService.onToast.subscribe((toastMessage: any) => this.messageService.add(toastMessage));
    this.toastService.onConfirm.subscribe((data) => this.showConfirmation(data));
    this.progressService.onIsWaiting.subscribe((isWaiting) => this.isWaiting = isWaiting);
  }

  async ngOnInit() {
    const success = await this.userService.getUser(false);

    await this.checkExceptionLog(success);
    this.ready = true;
  }

  async checkExceptionLog(success: boolean) {
    if (!success) {
      const lastExceptionResponse = await this.exceptionLogService.getLastExceptionLog();
      this.lastExceptionLog = lastExceptionResponse.data;

      if (this.lastExceptionLog)
        this.toastService.showInfo(
          `Last report: ${this.lastExceptionLog.createdDate}`,
          Messages.Exception.LastExceptionTitle, 5000);
      else
        this.toastService.showError(
          Messages.Exception.ApiExceptionMessage,
          Messages.Exception.ApiExceptionTitle, 5000);
    }
  }

  get loadingText() {
    if (!this.ready)
      return `${this.title} ${Messages.Information.IsLoadingTitle}`;

    return null;
  }

  get fadeDuration() {
    return this.isWaiting === undefined ? 0 : 500;
  }

  showConfirmation(data: any) {
    this.confirmationService.confirm({
      message: data.message,
      header: data.header,
      accept: async () => {
        if (data.acceptFunction)
          await data.acceptFunction(data.acceptFunctionParameter);
      },
      reject: async () => {
        if (data.rejectFunction)
          await data.rejectFunction(data.rejectFunctionParameter);
      },
      acceptLabel: data.acceptLabel,
      rejectLabel: data.rejectLabel,
      acceptButtonStyleClass: data.acceptButtonStyleClass, 
      rejectButtonStyleClass: data.rejectButtonStyleClass
    });
  }

  result!: CustomResponse<any[]>;
  async onGetExceptions() {
    let result = await this.apiDataService.getExceptions();
    //TODO: CreateAndAssignArray
    this.result = result;
  }
}
