import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Category } from '../../models/api/category.model';
import { CustomResponse } from '../../models/api/error/custom-response.model';
import { createAndAssignArray } from '../../utility/common.utils';
import { StateService } from '../state.service';
import { ApiDataService } from './api-data.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  categories$!: Observable<Category[]>;

  constructor(private stateService: StateService,
    private apiDataService: ApiDataService) {
    this.categories$ = this.stateService.categories$;

    this.apiDataService.getGetagoriesAsync()
      .subscribe((categoriesResponse: CustomResponse<Category[]>) => {
        this.updateCategoriesSubject(createAndAssignArray<Category>(Category, categoriesResponse.data));
      });
  }

  private updateCategoriesSubject(categories: Category[]) {
    this.stateService.updateCategoriesSubject(categories);
  }
}
