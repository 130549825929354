import { NgModule } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { EditorModule } from 'primeng/editor';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenubarModule } from 'primeng/menubar';
import { PickListModule } from 'primeng/picklist';
import { ScrollerModule } from 'primeng/scroller';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';



@NgModule({
  imports: [
    AutoCompleteModule,
    ButtonModule,
    CardModule,
    ChipModule,
    ChipsModule,
    ConfirmDialogModule,
    ContextMenuModule,
    DialogModule,
    DividerModule,
    EditorModule,
    InputGroupModule,
    InputGroupAddonModule,
    InputTextModule,
    InputTextareaModule,
    MenubarModule,
    PickListModule,
    ScrollerModule,
    ScrollPanelModule,
    SelectButtonModule,
    ToolbarModule,
    ToastModule,
    TableModule
  ],
  exports: [
    AutoCompleteModule,
    ButtonModule,
    CardModule,
    ChipModule,    
    ChipsModule,
    ConfirmDialogModule,
    ContextMenuModule,
    DialogModule,
    DividerModule,
    EditorModule,
    InputGroupModule,
    InputGroupAddonModule,
    InputTextModule,
    InputTextareaModule,
    MenubarModule,
    PickListModule,
    ScrollerModule,
    ScrollPanelModule,
    SelectButtonModule,
    ToolbarModule,
    ToastModule,
    TableModule
  ],
  providers: [MessageService]
})
export class PrimeNGModule { }
