export class UserLogin {
  userName: string = '';
  password: string = '';

  valid() {
    if (!this.userName || this.userName?.length == 0)
      return false;

    if (!this.password || this.password.length == 0)
      return false;

    return true;
  }
}
