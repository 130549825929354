import { Component, Input } from '@angular/core';
import { RouterLinkEnum } from '../../../../models/enums/router-link.enum';
import { UserService } from '../../../../services/data/user.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss'
})
export class SettingsComponent {
  @Input() loggedIn: boolean;
  @Input() buttonType = 'mat-button'
  @Input() showName = true;

  RouterLinkEnum = RouterLinkEnum;

  constructor(private userService: UserService) {
  }

  onLogout() {
    this.userService.logout();
  }
}
