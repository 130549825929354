<div class="flex justify-content-end">
    <button mat-mini-fab type="submit" (click)="onSubmit()" [class.spinner]="isProcessing$ | async"
        [disabled]="isProcessing$ | async" color="primary" matTooltip="Save Catalog" aria-label="Add Catalog"
        class="mx-1 glow-button">
        <mat-icon>save</mat-icon>
    </button>
    <button mat-mini-fab type="button" (click)="onCancelCatalog()" matTooltip="Cancel" aria-label="Cancel Add Catalog"
        color="warn" class="mx-1 glow-button">
        <mat-icon>cancel</mat-icon>
    </button>
</div>