import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';
import { Subscription, filter } from 'rxjs';
import { Messages } from '../../../common/messages.enums';
import { DrawerStateEnum } from '../../../models/enums/drawer-state.enum';
import { ListDisplayType } from '../../../models/enums/list-display-type.enum';
import { CatalogService } from '../../../services/data/catalog.service';
import { ToastService } from '../../../services/toast.service';

@Component({
  selector: 'app-catalogs',
  templateUrl: './catalogs.component.html',
  styleUrls: ['./catalogs.component.css']
})
export class CatalogsComponent implements OnDestroy {
  catalogs$ = this.catalogService.catalogs$
  isProcessing$ = this.catalogService.catalogsProcessing$;
  isSuccessful$ = this.catalogService.catalogsSuccessful$;

  routeSubscription: Subscription;
  routerSubscription: Subscription;

  isRefreshing = false;
  drawerState: DrawerStateEnum = DrawerStateEnum.Full;
  activeLinkId: number;

  showManageCatalogDialog = false;

  ListDisplayType = ListDisplayType;
  DrawerStateEnum = DrawerStateEnum;

  constructor(
    private catalogService: CatalogService,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService
  ) {

    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.routeChanged();
      });

    this.routeChanged();
  }

  ngOnDestroy() {
    this.routeSubscription?.unsubscribe();
    this.routerSubscription?.unsubscribe();
  }

  routeChanged(): void {
    let route = this.route;

    while (route.firstChild) {
      route = route.firstChild;
    }

    route.paramMap.subscribe((paramsMap: ParamMap) =>
      this.activeLinkId = +paramsMap.get('catalogId'));
  }


  onSelectCatalog(id: number) {
    this.catalogService.goToCatalog(id);
  }

  async onDeleteCatalog(id: number) {
    const catalog = await this.catalogService.getCatalog(id);
    const message = `Are you sure you would like to <strong class="text-red-500">delete</strong> catalog <i><strong>${catalog.name}</strong></i>?`;
    this.toastService.showConfirmation('Delete Catalog', message,
      this.acceptDelete.bind(this), id, () => { }, null, 'Delete', 'Cancel', 'warn', ''
    );
  }

  async acceptDelete(id) {
    const isSuccessful = await this.catalogService.deleteCatalog(id);

    if (isSuccessful && id == this.activeLinkId)
      this.catalogService.goToCatalogs();
  }

  onToggleDrawer(state: DrawerStateEnum) {
    console.log('onToggleDrawer', state)
    this.drawerState = state;
  }

  get loadingText() {
    return `Catalogs ${Messages.Information.IsLoadingTitle}`;
  }
}
