import { AfterViewInit, ContentChild, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appStickyContent]'
})
export class StickyContentDirective implements AfterViewInit {
  @Input() stickyOffset: number = 0;
  @Input() stickyTop: boolean = true;
  
  @ContentChild('stickyHeader', { static: true }) stickyHeader!: ElementRef<HTMLElement>;
  @ContentChild('stickyContent', { static: true }) stickyContent!: ElementRef<HTMLElement>;
  @ContentChild('stickyFooter', { static: true }) stickyFooter!: ElementRef<HTMLElement>;
  
  zIndex = 1; //3;
  headerHeight = 0;
  contentHeight = 0;

  constructor() { }

  // @HostListener('window:resize')
  // onResize() {
  //   this.setSticky();
  // }

  ngAfterViewInit(): void {
    this.setSticky();
  }

  setSticky() {
    if (this.stickyHeader?.nativeElement) {
      this.headerHeight = this.stickyHeader.nativeElement.clientHeight;
      this.setStyle(this.stickyHeader.nativeElement, this.stickyOffset, `${this.zIndex - 1}`);
    }

    if (this.stickyContent?.nativeElement) {
      let maxHeight = 0;
      if (this.stickyContent.nativeElement.style.maxHeight.indexOf('px') > 0)
        maxHeight = +this.stickyContent.nativeElement.style.maxHeight.replace('px', '');

      this.contentHeight = Math.max(this.stickyContent.nativeElement.clientHeight, maxHeight);

      const stickyHeightOffset = this.headerHeight + this.stickyOffset;
      this.setStyle(this.stickyContent.nativeElement, stickyHeightOffset, `${this.zIndex}`);
    }

    if (this.stickyFooter?.nativeElement) {
      const stickyHeightOffset = this.headerHeight + this.contentHeight + this.stickyOffset;
      this.setStyle(this.stickyContent.nativeElement, stickyHeightOffset, `${this.zIndex - 1}`);
    }
  }

  private setStyle(nativeElement: HTMLElement, stickyHeightOffset: number, zIndex: string) {
    nativeElement.style.position = '-webkit-sticky';
    nativeElement.style.position = 'sticky';
    nativeElement.style.zIndex = zIndex;

    if (this.stickyTop) {
      nativeElement.style.top = `${stickyHeightOffset}px`;
    } else {
      nativeElement.style.bottom = `${stickyHeightOffset}px`;
      nativeElement.style.alignContent = 'end';
    }
  }
}
