import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-debug-json',
  templateUrl: './debug-json.component.html',
  styleUrl: './debug-json.component.scss'
})
export class DebugJsonComponent {
  @Input() value: object;
  @Input() header: string;
  @Input() fullHeight: boolean = false;
}
