<p-card header="Login" styleClass="h-full">
    <form #loginForm="ngForm" (ngSubmit)="onLogin(loginForm)">
        <div class="field">
            <label for="login-username">UserName</label>
            <input #userName="ngModel" id="login-username" name="userName" type="text" [(ngModel)]="userLogin.userName" 
                required pInputText autocomplete="on" class="w-full" />
            <small class="text-red-500">
                <span *ngIf="isInvalid(userName)">
                    Username is required</span>
            </small>
        </div>
        <div class="field">
            <label for="login-password">Password</label>
            <input #password="ngModel" id="login-password" name="password" type="password" [(ngModel)]="userLogin.password" 
                required pInputText autocomplete="on" class="w-full" />
            <small class="text-red-500">
                <span *ngIf="isInvalid(password)">
                    Password is required</span>
            </small>
        </div>
        <div class="field">
            <button pButton label="Login" [loading]="processing" lass="p-button-primary">
            </button>
        </div>
    </form>
</p-card>