import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from '../../../services/data/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent {
  user$: Observable<any>;

  image: string = null;

  constructor(private userService: UserService) {
    this.user$ = this.userService.user$;
  }
}
