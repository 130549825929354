<div class="py-2">
    <app-toolbar color="secondary">
        <div left>
            <button mat-mini-fab class="mx-1" (click)="onManageCatalog(ManageMode.New)" color="primary"
                matTooltip="Add Catalog (full page)" aria-label="Add Catalog">
                <mat-icon><span class="material-icons">
                        add_note
                    </span></mat-icon>
            </button>
        </div>
        <div center>
            <div class="font-bold text-xl">Catalogs</div>
        </div>
        <div right>
            <button mat-mini-fab [class.spinner]="isRefreshing" [disabled]="isRefreshing" (click)="onRefresh()"
                color="primary" aria-label="Refresh Catalogs list">
                <mat-icon>refresh</mat-icon>
            </button>
        </div>
    </app-toolbar>
</div>