import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './modules/material.module';
import { PrimeNGModule } from './modules/primeng.module';

import { AppRoutingModule } from './app-routing.module';
import { AuthInterceptor } from './interceptors/auth-interceptor.interceptor';

import { FixedHeightDirective } from './directives/fixed-height.directive';
import { ProgressDirective } from './directives/progress.directive';

import { ForbiddenUserNameValidatorDirective } from './directives/validators/forbidden-username-validator.directive';
import { DuplicateUserNameValidatorDirective } from './directives/validators/duplicate-username-validator.directive';

import { AppComponent } from './app.component';
import { CatalogComponent } from './components/catalogs/catalog/catalog.component';
import { CatalogsComponent } from './components/catalogs/catalogs/catalogs.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { ButtonComponent } from './components/display/button/button.component';
import { CardComponent } from './components/display/card/card.component';
import { ListComponent } from './components/display/list/list.component';
import { ProgressComponent } from './components/display/progress/progress.component';
import { ShowMoreComponent } from './components/display/show-more/show-more.component';
import { ToolbarComponent } from './components/display/toolbar/toolbar.component';
import { DescriptionComponent } from './components/information/description/description.component';
import { ItemComponent } from './components/items/item/item.component';
import { ItemsComponent } from './components/items/items/items.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { MenuComponent } from './components/layout/menu/menu.component';
import { PagesComponent } from './components/layout/menu/pages/pages.component';
import { SettingsComponent } from './components/layout/menu/settings/settings.component';
import { LoginRegisterComponent } from './components/user/login-register/login-register.component';
import { LoginComponent } from './components/user/login/login.component';
import { ProfileComponent } from './components/user/profile/profile.component';
import { RegisterComponent } from './components/user/register/register.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { StickyContentDirective } from './directives/sticky-content.directive';
import { AddItemComponent } from './components/items/add-item/add-item.component';
import { EditorComponent } from './components/display/editor/editor.component';
import { PageHeadingComponent } from './components/display/page-heading/page-heading.component';
import { CatalogActionsComponent } from './components/catalogs/catalog/catalog-actions/catalog-actions.component';
import { ManageCatalogFormComponent } from './components/catalogs/manage-catalog/manage-catalog-form/manage-catalog-form.component';
import { ManageCatalogActionsComponent } from './components/catalogs/manage-catalog/manage-catalog-actions/manage-catalog-actions.component';
import { ManageCatalogComponent } from './components/catalogs/manage-catalog/manage-catalog.component';
import { SelectEntityComponent } from './components/display/select-entity/select-entity.component';
import { CatalogsActionsComponent } from './components/catalogs/catalogs/catalogs-actions/catalogs-actions.component';
import { ManageItemsComponent } from './components/items/manage-items/manage-items.component';
import { DebugJsonComponent } from './components/debug/debug-json/debug-json.component';


@NgModule({
  declarations: [
    //Directives
    ProgressDirective,
    FixedHeightDirective,

    //Validators
    ForbiddenUserNameValidatorDirective,
    DuplicateUserNameValidatorDirective,

    //Components
    AppComponent,
    ManageCatalogFormComponent,
    CatalogComponent,
    CatalogsComponent,
    CategoriesComponent,
    ButtonComponent,
    CardComponent,
    ListComponent,
    ProgressComponent,
    ShowMoreComponent,
    ToolbarComponent,
    DescriptionComponent,
    ItemComponent,
    ItemsComponent,
    FooterComponent,
    HeaderComponent,
    MenuComponent,
    PagesComponent,
    SettingsComponent,
    LoginRegisterComponent,
    LoginComponent,
    ProfileComponent,
    RegisterComponent,
    WelcomeComponent,
    StickyContentDirective,
    AddItemComponent,
    EditorComponent,
    ManageCatalogActionsComponent,
    ManageCatalogComponent,
    PageHeadingComponent,
    CatalogActionsComponent,
    SelectEntityComponent,
    CatalogsActionsComponent,
    ManageItemsComponent,
    DebugJsonComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    MaterialModule,
    PrimeNGModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    provideAnimationsAsync()],
  bootstrap: [AppComponent]
})
export class AppModule { }
