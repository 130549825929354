<div class="grid">
    <div class="col-12 md:col-6">
        <app-card title="Catalog">
            <div subtitle>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et neque at leo laoreet gravida
                nec sed elit. Ut quis tempus odio. Donec a vehicula mi. Sed sagittis mi ac ante volutpat tristique.
                Nulla molestie orci vitae tellus. Integer eget est nec risus cursus malesuada.</div>
            <div actions>
                <button mat-button [routerLink]="routerLinkEnum.Catalogs">Get started with Catalogs</button>
            </div>
        </app-card>
    </div>
    <div class="col-12 md:col-6">
        <app-card title="Items">
            <div subtitle>LNullam eu purus imperdiet, consequat turpis sit amet, mollis justo. Nam mollis nunc ac risus
                feugiat rutrum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vel aliquam nisl.
                Quisque at ex arcu. Duis et dignissim massa, non auctor mi.</div>
            <button mat-button [routerLink]="routerLinkEnum.Items">
            </button>
        </app-card>
    </div>
    <div class="col-12 md:col-6">
        <app-card title="Catagories">
            <div subtitle>Vivamus vel libero turpis. Praesent feugiat blandit tellus, nec scelerisque leo efficitur non.
                Vestibulum at cursus lacus. In ultrices quam sit amet elit euismod, maximus condimentum ipsum
                scelerisque. In hac habitasse platea dictumst. Suspendisse vel quam nunc. Aliquam semper dignissim nisl.
            </div>
            <button mat-button [routerLink]="routerLinkEnum.Categories">Get started with Categories</button>
        </app-card>
    </div>
    <div class="col-12 md:col-6">
        <app-card title="Manage Profile">
            <div subtitle>Mauris vestibulum sodales nulla vel volutpat. Donec tincidunt fringilla augue, eget luctus
                eros aliquam quis. Donec tempus, urna a fringilla pretium, mi tortor porttitor tortor, vitae sodales
                purus lorem id mauris. Vestibulum sodales efficitur metus non faucibus. Nunc lobortis dolor eget
                interdum porttitor. Mauris vestibulum sodales nulla vel volutpat. Donec tincidunt fringilla augue, eget
                luctus eros aliquam quis. Donec tempus, urna a fringilla pretium, mi tortor porttitor tortor, vitae
                sodales purus lorem id mauris. Vestibulum sodales efficitur metus non faucibus. Nunc lobortis dolor eget
                interdum porttitor.</div>
            <button mat-button [routerLink]="routerLinkEnum.Profile">Manage</button>
        </app-card>
    </div>
</div>