import { EventEmitter, Injectable } from '@angular/core';
import { Message } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  onToast = new EventEmitter<Message>();
  onConfirm = new EventEmitter<any>();

  constructor() { }

  showSuccess(message: string, title?: string, life: number = 3000) {
    this.onToast.next({ severity: 'success', summary: title ?? 'Success', detail: message, life: life });
  }

  showInfo(message: string, title?: string, life: number = 3000) {
    this.onToast.next({ severity: 'info', summary: title ?? 'Info', detail: message, life: life });
  }

  showWarn(message: string, title?: string, life: number = 4000) {
    this.onToast.next({ severity: 'warn', summary: title ?? 'Warn', detail: message, life: life });
  }

  showError(message: string, title?: string, life: number = 5000) {
    this.onToast.next({ severity: 'error', summary: title ?? 'Error', detail: message, life: life });
  }

  showContrast(message: string, title?: string, life: number = 3000) {
    this.onToast.next({ severity: 'contrast', summary: title ?? 'Error', detail: message, life: life });
  }

  showSecondary(message: string, title?: string, life: number = 3000) {
    this.onToast.next({ severity: 'secondary', summary: title ?? 'Secondary', detail: message, life: life });
  }

  showConfirmation(header: string, message: string, acceptFunction: Function, acceptFunctionParameter: any = null,
    rejectFunction: Function = null, rejectFunctionParameter: any = null, 
    acceptLabel: string = 'Yes', rejectLabel: string = 'No',
    acceptButtonStyleClass = 'primary', rejectButtonStyleClass = '', 
  ) {
    this.onConfirm.next({ header, message, acceptFunction, acceptFunctionParameter, rejectFunction, rejectFunctionParameter, 
      acceptLabel, rejectLabel, acceptButtonStyleClass, rejectButtonStyleClass });
  }
}
