import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ListDisplayType } from '../../../models/enums/list-display-type.enum';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrl: './list.component.scss'
})
export class ListComponent {
  @Input() values: any[] = [];
  @Input() type: number = 0;
  @Input() activeLinkId: number;

  @Output() onValueClicked = new EventEmitter<number>();
  @Output() onValueDeleted = new EventEmitter<number>();

  ListDisplayType = ListDisplayType;

  onClick(id: number) {
    this.onValueClicked.emit(id);
    this.activeLinkId = id;
  }

  onDelete(id: number) {
    this.onValueDeleted.emit(id);
  }
}
