import { ContentChild, Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appFixedHeight]'
})
export class FixedHeightDirective {
  @Input() lockHeight = true;

  @ContentChild('stickyHeader', { static: true }) stickyHeader!: ElementRef<HTMLElement>;

  initialHeight: number;
  appFooterHeight: number = 50;

  constructor(private el: ElementRef) { }

  ngAfterViewInit() {
    this.setInitialHeight();
  }

  @HostListener('window:resize')
  onResize() {
    this.setInitialHeight();
  }

  private setInitialHeight() {
    const header = this.el.nativeElement.parentNode?.firstChild;
    const footer = this.el.nativeElement.parentNode?.children[2];
    const headerHeight = header?.clientHeight ?? 0;
    const footerHeight = footer?.clientHeight ?? 0;

    const viewportHeight = window.innerHeight - headerHeight - footerHeight - this.appFooterHeight;
    this.initialHeight = Math.min(this.el.nativeElement.clientHeight, viewportHeight);
    
    if (this.lockHeight) {
      const height = Math.max(this.initialHeight, viewportHeight);
      this.el.nativeElement.style.maxHeight = `${height}px`;
    } else {
      this.el.nativeElement.style.height = `${this.initialHeight}px`;
    }
    this.el.nativeElement.style.overflowY = 'auto';
  }
}
