<div class="h-full flex flex-column" appStickyContent>
    <div #stickyHeader class="pt-3 pb-2 text-center font-bold">
        <ng-content select="[header]"></ng-content>
    </div>
    <div #stickyContent class="flex-grow-1 h-full" appFixedHeight>
        @switch (type) {
        @case (ListDisplayType.Generic) {
        <div *ngFor="let value of values">
            {{ value.name }}
        </div>
        }
        @case (ListDisplayType.ScrollTable) {
        <p-table [value]="values" class="h-full" [scrollable]="true">
            <ng-template pTemplate="body" let-value>
                <tr class="pointer">
                    <td class="item">
                        <div class="flex align-items-center my-1" [class.active]="value.id == activeLinkId"
                            [style.height.px]="65">
                            <div (click)="onClick(value.id)" class="flex-grow-1 p-3 font-bold">
                                {{ value.name }}
                            </div>
                            <div class="flex-grow-0">
                                <button mat-icon-button class="delete-button icon-button-small mx-1 border-1"
                                    (click)="onDelete(value.id)" color="warn" matTooltip="Delete" aria-label="Delete">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        }
        @case (ListDisplayType.Scroller) {
        <p-scroller [items]="values" [itemSize]="50" scrollHeight="200px" styleClass="border-1 surface-border"
            [style]="{'width': '200px', 'height': '75cqh'}">
            <ng-template pTemplate="item" let-value let-options="options">
                <div (click)="onClick(value.id)" class="pointer">{{ value.name }}</div>
            </ng-template>
        </p-scroller>
        }
        @case (ListDisplayType.ScrollPanel) {
        <p-scrollPanel [style]="{ width: '100%', height: '100cqh' }">
            <div *ngFor="let value of values">
                <div (click)="onClick(value.id)" class="pl-1 pt-4 pointer">{{ value.name }}</div>
            </div>
        </p-scrollPanel>
        }
        @case (ListDisplayType.Card) {
        <div class="flex">
            <p-card *ngFor="let value of values" styleClass="h-full" class="p-1 col-3">
                <div class="font-bold">{{ value.name }}</div>
                <div *ngIf="value.description">{{ value.description }}</div>
            </p-card>
        </div>
        }
        }
    </div>
</div>