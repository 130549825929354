<div *ngIf="item; else noItem">    
    <div class="font-bold">{{ item.name }}</div>
    <app-show-more [text]="item.description ?? '&nbsp'" [stickyTop]="false" [stickyPadding]="25" [inline]="true" clampLines="1"></app-show-more>
</div>
<ng-template #noItem>
    <app-debug-json header="No Item" [value]="{
        catalogId: catalogId,
        item: item,
        items$: items$ | async
    }"></app-debug-json>
</ng-template>