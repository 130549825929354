<div class="app-container pt-3 pb-1 px-3 col-12 xl:col-10 xl:col-offset-1">
  <header class="header">
    <app-header></app-header>
  </header>
  <main class="app-content" [appProgress]="!ready || isWaiting" [fadeDuration]="fadeDuration" [text]="loadingText">
    @if (ready) {
    <router-outlet></router-outlet>
    }
  </main>
  <footer class="footer">
    <app-footer class="flex-shrink-1 pb-4"></app-footer>
  </footer>
</div>

<p-toast position="bottom-right" [breakpoints]="{ '920px': { width: '100%', right: '2%', left: '0' } }"
  [style]="{'white-space': 'pre-line'}">
</p-toast>

<div class="card flex justify-content-center">
  <p-confirmDialog #cd>
    <ng-template pTemplate="headless" let-message>
      <div class="flex flex-column align-items-center px-5 pb-5 surface-overlay border-round">
        <span class="font-bold text-2xl block mb-2 mt-4">
          {{ message.header }}
        </span>
        <p class="mb-0" [innerHtml]="message.message"></p>
        <div class="flex align-items-center gap-2 mt-4">
          <button mat-raised-button (click)="cd.accept()" [color]="message.acceptButtonStyleClass">{{
            message.acceptLabel }}</button>
          <button mat-raised-button (click)="cd.reject()" [color]="message.rejectButtonStyleClass">{{
            message.rejectLabel }}</button>
        </div>
      </div>
    </ng-template>
  </p-confirmDialog>
</div>
