export namespace Messages {
  export enum Information {
    Title = 'Pocket List',
    IsLoadingTitle = 'is loading...',
    FooterText = 'Pocket List by Derek Harding'
  }
  export enum Exception {
    ApiExceptionTitle = 'API Exception',
    ApiExceptionMessage = 'Unable to contact API, please try again later.',
    LastExceptionTitle = 'Last Exception'
  }
}