import { Component } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ErrorData } from '../../../models/api/error/error-data.model';
import { UserRegistration } from '../../../models/api/user/user-registration.model';
import { ControlValidationService } from '../../../services/control-validation.service';
import { UserService } from '../../../services/data/user.service';
import { FormService } from '../../../services/form.service';
import { ProgressService } from '../../../services/progress.service';
import { ToastService } from '../../../services/toast.service';
import { createAndAssign } from '../../../utility/common.utils';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent {
  userRegistration: UserRegistration = new UserRegistration;
  confirmPasswordInput: string = '';
  processing: boolean = false;
  userRegistrationError: ErrorData | null = null;

  constructor(
    private progressService: ProgressService,
    private userService: UserService,
    private toastService: ToastService,
    private formService: FormService,
    private controlValidationService: ControlValidationService
  ) {}

  async onRegister(form: any) {
    this.userRegistrationError = null;
    this.isProcessing(true);
    try {
      if (form.invalid) {
        this.formService.markFormGroupDirty(form);
        this.toastService.showError('Registration details are invalid');
        return;
      }

      const userRegistration = createAndAssign(UserRegistration, form.value);
      this.userRegistrationError = await this.userService.register(userRegistration);

      const toastMessage = this.userRegistrationError?.message ?? null;

      if (toastMessage) {
        this.toastService.showError(toastMessage, "Registration failed");
      }
    } finally {
      this.isProcessing(false);
    }
  }

  private isProcessing(value: boolean) {
    this.processing = value;
    this.progressService.onIsWaiting.emit(value);
  }

  isInvalid(control: NgModel): boolean {
    return this.controlValidationService.isInvalid(control);
  }
  
  isInvalidCustom(control: NgModel, errorName: string, isDirtyAndTouched: boolean): boolean {
    return this.controlValidationService.isInvalidCustom(control, errorName, isDirtyAndTouched);
  }
  
  isRequired(control: NgModel): boolean {
    return this.controlValidationService.isRequired(control);
  }
  
  isPending(control: NgModel): boolean {
    return this.controlValidationService.isPending(control);
  }
  
  isEmailInvalid(control: NgModel): boolean {
    return this.controlValidationService.isEmailInvalid(control);
  }
  
  isPasswordsDoNotMatch(password: NgModel, confirmPassword: NgModel): boolean {
    return this.controlValidationService.isPasswordsDoNotMatch(password, confirmPassword);
  }
}
