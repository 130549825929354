import { Component, EventEmitter, Output } from '@angular/core';
import { ManageCatalogService } from '../manage-catalog.service';

@Component({
  selector: 'app-manage-catalog-actions',
  templateUrl: './manage-catalog-actions.component.html',
  styleUrl: './manage-catalog-actions.component.scss'
})
export class ManageCatalogActionsComponent {
  isProcessing$ = this.manageCatalogService.isProcessing$;

  @Output() onFormSubmitted = new EventEmitter<void>();

  constructor(private manageCatalogService: ManageCatalogService) { }

  async onSubmit() {
    this.onFormSubmitted.emit();
    await this.manageCatalogService.manageCatalog();
  }

  async onCancelCatalog() {
    this.manageCatalogService.cancelManageCatalog();
  }
}
