import { Injectable } from '@angular/core';
import { User } from '../models/user/user.model';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor() { }

  setUser(user: User) {
    if (user == null) {
      sessionStorage.removeItem('user');
    } else {
      sessionStorage.setItem('user', JSON.stringify(user));
    }
  }

  gettUser(): User {
    const user = sessionStorage.getItem('user');
    if (user && user !== 'undefined')
      return JSON.parse(user);

    return null;
  }
}
