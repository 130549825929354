import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import { CatalogItem } from '../../models/api/catalog-item.model';
import { CustomResponse } from '../../models/api/error/custom-response.model';
import { StateType } from '../../models/enums/state-type.enum';
import { createAndAssignArray } from '../../utility/common.utils';
import { StateService } from '../state.service';
import { ApiDataService } from './api-data.service';

@Injectable({
  providedIn: 'root'
})
export class CatalogItemService {
  catalogItems$ = this.stateService.catalogItems$;

  private _catalogItemIdsSubject: BehaviorSubject<Array<number>> = new BehaviorSubject<Array<number>>(null);
  catalogItemIds$: Observable<Array<number>> = this._catalogItemIdsSubject.asObservable();

  catalogItemsProcessing$: Observable<boolean> = this.stateService.getState(`${StateType.CatalogItems}${StateType.IsProcessing}`, true);
  catalogItemsSuccessful$: Observable<boolean> = this.stateService.getState(`${StateType.CatalogItems}${StateType.IsProcessing}`, true);

  catalogItemIdsProcessing$: Observable<boolean> = this.stateService.getState(`${StateType.CatalogItemIds}${StateType.IsProcessing}`, true);

  constructor(private stateService: StateService,
    private apiDataService: ApiDataService) {
  }

  /** Get CatalogItems from API and sets Processing subject */
  getCatalogItems(catalogId: number): Observable<Array<CatalogItem>> {
    let catalogItems: Array<CatalogItem>;

    return this.apiDataService.getCatalogItemsAsync(catalogId).pipe(
      tap((catalogsResponse: CustomResponse<Array<CatalogItem>>) => {
        catalogItems = createAndAssignArray<CatalogItem>(CatalogItem, catalogsResponse.data);
        this.updateCatalogItemsSubject(catalogItems);
        this.stateService.setState(`${StateType.CatalogItems}${StateType.IsSuccessful}`, catalogsResponse.isSuccessful);
        this.stateService.setState(`${StateType.CatalogItems}${StateType.IsProcessing}`, false);
      }),
      map(() => catalogItems)
    );
  }

  selectCatalogItem(catalogId: number) {
    this.stateService.setState(`${StateType.CatalogItemIds}${StateType.IsProcessing}`, true);
    this.getCatalogItems(catalogId)
      .subscribe((catalogItems: Array<CatalogItem>) => {
        const itemIds = catalogItems.map(x => x.itemId);
        this.updateCatalogItemIdsSubject(itemIds);
        this.stateService.setState(`${StateType.CatalogItemIds}${StateType.IsProcessing}`, false);
      });
  }

  /** Update BehaviorSubject in StateService */
  private updateCatalogItemsSubject(catalogItems: Array<CatalogItem>) {
    this.stateService.updateCatalogItemsSubject(catalogItems);
  }

  /** Update BehaviorSubject in this service */
  private updateCatalogItemIdsSubject(catalogItems: Array<number>) {
    this._catalogItemIdsSubject.next(catalogItems);
  }
}
