import { Injectable } from '@angular/core';
import { CustomResponse } from '../models/api/error/custom-response.model';
import { ErrorData } from '../models/api/error/error-data.model';
import { createAndAssign } from '../utility/common.utils';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(private toastService: ToastService) { }

  handleResponseError(response: CustomResponse<any>) { 
    let title = 'Error';
    let message = null;

    if (!response.isSuccessful) {
      const errorData = createAndAssign(ErrorData, response.error);
  
      if (errorData.isReported)
        return;
  
      if (errorData.validationMessages.length > 0) {
        message = errorData.validationMessages.joinForToast();
      }
      
      if (errorData.message) {
        if (!message) {
            message = errorData.message;
        } else {
            title = errorData.message;
        }
      }
    }
  
    if (message)
      this.handleError(message, title);
  }

  handleError(message: string, title: string) {
    this.toastService.showError(message, title);
  }
  
  isResponseSuccessful(response: CustomResponse<any>) {
    return response.isSuccessful && !response.isFatal;
  }
}
