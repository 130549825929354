import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, first, firstValueFrom, lastValueFrom, map, tap } from 'rxjs';
import { Catalog } from '../../models/api/catalog.model';
import { CustomResponse } from '../../models/api/error/custom-response.model';
import { ManageCatalog } from '../../models/api/manage-catalog.model';
import { ManageMode } from '../../models/enums/manage-mode.enum';
import { RouterLinkEnum } from '../../models/enums/router-link.enum';
import { StateType } from '../../models/enums/state-type.enum';
import { createAndAssign, createAndAssignArray } from '../../utility/common.utils';
import { ErrorService } from '../error.service';
import { StateService } from '../state.service';
import { ToastService } from '../toast.service';
import { ApiDataService } from './api-data.service';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {
  catalogs$ = this.stateService.catalogs$;
  catalog$ = this.stateService.catalog$

  catalogsSuccessful$: Observable<boolean> = this.stateService.getState(`${StateType.Catalogs}${StateType.IsSuccessful}`);
  catalogsProcessing$: Observable<boolean> = this.stateService.getState(`${StateType.Catalogs}${StateType.IsProcessing}`);

  catalogProcessing$: Observable<boolean> = this.stateService.getState(`${StateType.Catalog}${StateType.IsProcessing}`, true);
  catalogSuccessful$: Observable<boolean> = this.stateService.getState(`${StateType.Catalog}${StateType.IsSuccessful}`, true);

  constructor(private stateService: StateService,
    private apiDataService: ApiDataService,
    private errorService: ErrorService,
    private toastService: ToastService,
    private router: Router) {
    // initial get of Catalogs
    this.getCatalogs().subscribe();
  }

  /** Get Catalogs from API and sets Processing subject */
  getCatalogs(): Observable<void> {
    this.stateService.setState(`${StateType.Catalogs}${StateType.IsProcessing}`, true);
    return this.apiDataService.getCatalogsAsync().pipe(
      tap((catalogsResponse: CustomResponse<Array<Catalog>>) => {
        this.updateCatalogsSubject(createAndAssignArray<Catalog>(Catalog, catalogsResponse.data));
        this.stateService.setState(`${StateType.Catalogs}${StateType.IsSuccessful}`, catalogsResponse.isSuccessful);
        this.stateService.setState(`${StateType.Catalogs}${StateType.IsProcessing}`, false);
      }),
      map(() => undefined)
    );
  }

  /** Navigates to Catalogs */
  goToCatalogs() {
    this.router.navigate([RouterLinkEnum.Catalogs]);
  }

  /** Navigates to Catalogs/:catalogId */
  goToCatalog(id: number) {
    this.router.navigate([RouterLinkEnum.Catalogs, id]);
  }

  /** Navigates to a Catalog to Edit */
  goToEditCatalog(id: number) {
    this.router.navigate([RouterLinkEnum.Catalogs, id, RouterLinkEnum.Edit]);
  }

  /** Navigates to create a new Catalog */
  goToNewCatalog() {
    this.router.navigate([RouterLinkEnum.Catalogs, 0, RouterLinkEnum.New]);
  }

  /** .pipe(first()) solves an issue where Observable isn't ready yet */
  async getCatalog(id: number): Promise<Catalog> {
    const catalogs = await lastValueFrom(this.stateService.catalogs$.pipe(first()));
    return catalogs ? catalogs.find((catalog: Catalog) => catalog.id === id) : null;
  }

  /** Selects Catalog from existing Catalogs and set Processing subject */
  selectCatalog(id: number) {
    this.stateService.setState(`${StateType.Catalog}${StateType.IsProcessing}`, true);
    this.stateService.catalogs$.pipe(map((catalogs: Array<Catalog>) => {
      const catalog = catalogs ? catalogs.find((catalog: Catalog) => catalog.id == id) : null;
      return catalog;
    })).subscribe((catalog: Catalog) => {
      this.stateService.updateCatalogSubject(catalog);
      this.stateService.setState(`${StateType.Catalog}${StateType.IsSuccessful}`, catalog !== null);
      this.stateService.setState(`${StateType.Catalog}${StateType.IsProcessing}`, false);
    });
  }

  /** Update BehaviorSubject */
  private updateCatalogsSubject(catalogs: Array<Catalog>) {
    this.stateService.updateCatalogsSubject(catalogs);
  }

  /** Interacts with API to Edd or Edit Catalog */
  async manageCatalog(manageMode: ManageMode, catalogId: number, manageCatalog: ManageCatalog) {
    manageCatalog.trim();

    let manageCatalogResponse: CustomResponse<Catalog>;

    switch (manageMode) {
      case ManageMode.New:
        manageCatalogResponse = await this.apiDataService.addCatalog(manageCatalog);
        break;

      case ManageMode.Edit:
        manageCatalogResponse = await this.apiDataService.editCatalog(catalogId, manageCatalog);

        break;
    }

    if (manageCatalogResponse) {
      const catalog = createAndAssign<Catalog>(Catalog, manageCatalogResponse.data);
      await this.manageCatalogToCatalogsSubject(manageMode, catalog);
      return catalog.id;
    }

    return 0;
  }

  /** Delete Catalog */
  async deleteCatalog(id: number): Promise<boolean> {
    return await this.deleteCatalogs([id]);
  }

  /** Delete Catalogs, update BehaviorSubject and handle any error messages  */
  async deleteCatalogs(ids: number[]) {
    const deleteCatalogResponse = await this.apiDataService.deleteCatalogs(ids);
    this.errorService.handleResponseError(deleteCatalogResponse);

    const isSuccessful = this.errorService.isResponseSuccessful(deleteCatalogResponse);
    if (isSuccessful) {
      ids.forEach(async (id: number) => {
        await this.removeCatalogFromCatalogsSubject(id);
      });

      this.toastService.showSuccess('Catalog deleted');
    }

    return isSuccessful;
  }

  /** Updates Catalog BehavorSubject based on ManageMode */
  async manageCatalogToCatalogsSubject(manageMode: ManageMode, newCatalog: Catalog) {
    const existingCatalogs = await firstValueFrom(this.stateService.catalogs$);

    switch (manageMode) {
      case ManageMode.New:
        const newCatalogs = [...existingCatalogs, newCatalog];
        this.stateService.updateCatalogsSubject(newCatalogs);
        break;

      case ManageMode.Edit:
        const existingCatalogIndex = existingCatalogs.findIndex((catalog: Catalog) => catalog.id == newCatalog.id);

        if (existingCatalogIndex !== -1) {
          existingCatalogs[existingCatalogIndex] = newCatalog;
        }
        break;
    }
  }

  /** Removes Catalog from BehavorSubject */
  async removeCatalogFromCatalogsSubject(id: number) {
    const existingCatalogs = await firstValueFrom(this.stateService.catalogs$);
    const remainingCatalogs = existingCatalogs.filter(x => x.id !== id);
    const newCatalogs = [...remainingCatalogs];
    this.stateService.updateCatalogsSubject(newCatalogs);
  }
}
