import { Validation } from "../../common/validation.enums";
import { sanitize } from "../../utility/common.utils";

export class ManageCatalog {
    name: string = '';
    description: string = '';

    trim() {
        this.name = this.name.trim();
        this.santize();
    }

    santize() {
        this.description = this.description.trim();
        this.description = sanitize(this.description);
    }

    valid() {
        this.santize();

        let isValid = true;

        if (!this.name || this.name.length == 0)
            isValid = false;

        if (this.description.length > Validation.ManageCatalog.DescriptionMaxLength)
            isValid = false;

        return isValid;
    }
}
