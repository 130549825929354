import { Injectable } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor() { }

  markFormGroupDirty(form: NgForm) {
    Object.keys(form.controls).forEach(field => {
      const control = form.controls[field] as FormControl;
      control.markAsDirty({ onlySelf: true });
    });
  }
}
