export enum StateType {
  IsProcessing = 'IsProcessing',
  IsSuccessful = 'IsSuccessful',

  Catalogs = 'catalogs',
  Catalog = 'catalog',

  CatalogItems = 'catalogItems',
  CatalogItemIds = 'catalogItemIds',

  Items = 'Items',
  Item = 'Item',

  ManageMode =  'manageMode'
  }