export class UserRegistration {
  userName!: string;
  password!: string;
  email!: string;

  valid() {
    if (!this.userName || this.userName?.length == 0)
      return false;

    if (!this.password || this.password.length == 0)
      return false;

    if (!this.email || this.email.length == 0)
      return false;

    return true;
  }
}
