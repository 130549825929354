import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Category } from '../../models/api/category.model';
import { CategoryService } from '../../services/data/category.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrl: './categories.component.css'
})
export class CategoriesComponent {
  categories$: Observable<Category[]>;

  constructor(private categoryService: CategoryService) {
    this.categories$ = this.categoryService.categories$;
  }
}
