import { inject } from "@angular/core";
import { CanActivateFn, Router } from '@angular/router';
import { UserService } from "../services/data/user.service";
import { RouterLinkEnum } from "../models/enums/router-link.enum";

export const UserGuard: CanActivateFn = async () => {
  const userService: UserService = inject(UserService);
  const router: Router = inject(Router);

  return new Promise<boolean>((resolve) => {
    const subscription = userService.user$.subscribe({
      next: (currentUser) => {
        if (currentUser !== null) {
          resolve(true);
        } else {
          router.navigate([`/${RouterLinkEnum.Login}`]);
          resolve(false);
        }
      },
      error: (error) => {
        resolve(false);
      },
      complete: () => {
        subscription?.unsubscribe();
      }
    });
  });
};