import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CatalogService } from '../../../services/data/catalog.service';
import { CatalogItemService } from '../../../services/data/catalog-item.service';
import { Messages } from '../../../common/messages.enums';

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.css'] // Use styleUrls instead of styleUrl
})
export class CatalogComponent implements OnDestroy {
  selectedCatalog$ = this.catalogService.catalog$;
  catalogItemIds$ = this.catalogItemService.catalogItemIds$;

  catalogIsProcessing$ = this.catalogService.catalogProcessing$;
  catalogIsSuccessful$ = this.catalogService.catalogSuccessful$;

  catalogItemsIsProcessing$ = this.catalogItemService.catalogItemIdsProcessing$;

  catalogProcessingSubscription: Subscription;
  routeSubscription: Subscription;

  id: number = null;
  showMore: boolean = false;

  constructor(private catalogService: CatalogService,
    private catalogItemService: CatalogItemService,
    private route: ActivatedRoute) {
    this.routeSubscription = this.route.paramMap
      .subscribe(async (paramsMap) => {
        await this.refreshCatalog(+paramsMap.get('catalogId'));
      });
  }

  ngOnDestroy() {
    this.routeSubscription?.unsubscribe();
    this.catalogProcessingSubscription?.unsubscribe();
  }

  refreshCatalog(id: number) {
    this.showMore = false;
    this.id = id;
    this.catalogService.selectCatalog(this.id);
    this.catalogItemService.selectCatalogItem(this.id);
  }

  get loadingCatalogText() {
    return `Catalog ${Messages.Information.IsLoadingTitle}`;
  }

  get loadingItemIdsText() {
    return `Items ${Messages.Information.IsLoadingTitle}`;
  }
}
