import { Component, Input } from '@angular/core';
import { ItemService } from '../../../services/data/item.service';
import { last, Observable } from 'rxjs';
import { Item } from '../../../models/api/item.model';
import { StorageService } from '../../../services/storage.service';
import { StorageKeys } from '../../../common/storage-keys.enums';
import { ScreenSizeService } from '../../../services/screen-size.service';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrl: './items.component.css'
})
export class ItemsComponent {
  @Input() itemIds!: number[];
  @Input() catalogId!: number;

  rowOptions = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 }
  ];

  rowValue: any = 6;

  items$: Observable<Item[]>;

  screenSize = '';

  itemRowClass = 'col-12'
  selectableRowOptions: any[] = [];

  constructor(private itemService: ItemService,
    private storageService: StorageService,
    public screenSizeSerivce: ScreenSizeService
  ) {
    this.items$ = this.itemService.items$;

    this.screenSizeSerivce.screenSize$.subscribe(screenSize => {
      this.screenSize = screenSize;
      const settingValue = +this.storageService.getItem(`${StorageKeys.CatalogItemRows}_${this.screenSize}`);
      this.getRowClass(settingValue);
    }); 
  }

  onRowsClicked(event) {
    const value = event.option.value;
    this.storageService.setItem(`${StorageKeys.CatalogItemRows}_${this.screenSize}`, value);
    this.getRowClass(value);
  }

  getRowClass(settingValue: number) {
    switch (this.screenSize) {
      case 'xs':
        this.setOptions(settingValue, 1);
        break;
      case 'sm':
        this.setOptions(settingValue, 2);
        break;
      case 'md':
        this.setOptions(settingValue, 2);
        break;
      case 'lg':
        this.setOptions(settingValue, 3);
        break;
      case 'xl':
        this.setOptions(settingValue, 4);
        break;
      default:
        this.setOptions(settingValue, 1);
        break;
    }
  }

  setOptions(settingValue: number, maxValue: number) {
    const newValue = settingValue <= maxValue ? settingValue : maxValue;
    this.selectableRowOptions = this.rowOptions.filter(option => option.value <= maxValue);
    this.rowValue = newValue === 0 ? maxValue : newValue;
    this.itemRowClass = this.getItemRowClass();
  }
    
  getItemRowClass() {
    switch (this.rowValue) {
      case 1:
        return 'col-12';
      case 2:
        return 'col-6';
      case 3:
        return 'col-4';
      case 4:
        return 'col-3';
      default:
        return 'col-12';
    }
  }
}
