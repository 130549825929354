import { Component } from '@angular/core';
import { Messages } from '../../common/messages.enums';
import { UserService } from '../../services/data/user.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrl: './welcome.component.scss'
})
export class WelcomeComponent {
  user$ = this.userService.user$;
  loggedIn$ = this.userService.loggedIn$;

  welcomeToTitle = Messages.Information.Title;

  constructor(private userService: UserService) {
  }
}
