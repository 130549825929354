<div [ngClass]="{ 'progress-container-background': containerBackground }"
    class="absolute w-full h-full flex justify-content-center items-center z-5">
    <div class="flex items-center h-full child">
        <div class="flex flex-column items-center justify-content-center text-center">
            <div [ngClass]="{ 'progress-inner-border': innerBorder }">
                <mat-spinner class="mx-auto"></mat-spinner>
                @if (text?.length)
                {
                <div class="mt-4">{{ text }}</div>
                }
            </div>
        </div>
    </div>
</div>