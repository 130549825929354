<p-card header="Register new User" styleClass="h-full">
    <form #registerForm="ngForm" (ngSubmit)="onRegister(registerForm)">
        <div class="field">
            <label for="username">UserName</label>
            <p-inputGroup class="w-full">
                <input type="text" pInputText name="userName" [(ngModel)]="userRegistration.userName"
                    #userName="ngModel" required appForbiddenUserName appDuplicateUserName class="w-full" />
                <span *ngIf="isPending(userName)" class="p-inputgroup-addon spinner-addon">
                    <i class="pi pi-spin pi-spinner"></i>
                </span>
            </p-inputGroup>
            <small class="text-red-500">
                <span *ngIf="isRequired(userName)">
                    UserName is required</span>
                <span *ngIf="isInvalidCustom(userName, 'forbiddenUserName', true) || isInvalidCustom(userName, 'duplicateUserName', false)">
                    UserName is not allowed</span>
            </small>
        </div>
        <div class="field">
            <label for="email">Email</label>
            <input type="email" pInputText name="email" [(ngModel)]="userRegistration.email" #email="ngModel" required
                class="w-full" />
            <small class="text-red-500">
                <span *ngIf="isRequired(email)">
                    Email is required<br></span>
                <span *ngIf="isEmailInvalid(email)">
                    Email is invalid<br></span>
            </small>
        </div>
        <div class="field">
            <label for="new-password">Password</label>
            <input id="new-password" type="password" pInputText name="password" [(ngModel)]="userRegistration.password"
                #password="ngModel" required autocomplete="on" class="w-full" />
            <small class="text-red-500">
                <span *ngIf="isRequired(password)">
                    Password is required<br></span>
            </small>
        </div>
        <div class="field">
            <label for="confirm-password">Confirm Password</label>
            <input id="confirm-password" type="password" pInputText name="confirmPassword"
                [(ngModel)]="confirmPasswordInput" #confirmPassword="ngModel" required autocomplete="on"
                class="w-full" />
            <small class="text-red-500">
                <span *ngIf="isRequired(confirmPassword)">
                    Confirm password is required<br></span>
                <span *ngIf="isPasswordsDoNotMatch(password, confirmPassword)">
                    Passwords do not match<br></span>
            </small>
        </div>
        <div class="field">
            <button pButton label="Register" [loading]="processing" class="p-button-primary">
            </button>
        </div>
        <div *ngIf="userRegistrationError?.validationMessages">
            <div class="font-bold text-red-500">Registration was unsuccessful:</div>
            <ul>
                <li *ngFor="let error of userRegistrationError.validationMessages">{{ error }}</li>
            </ul>
        </div>
    </form>
</p-card>