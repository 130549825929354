import { Component } from '@angular/core';
import { Messages } from '../../../common/messages.enums';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css'
})
export class FooterComponent {
  footerText = Messages.Information.FooterText;;
}
