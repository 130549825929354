import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CatalogItem } from '../../models/api/catalog-item.model';
import { Catalog } from '../../models/api/catalog.model';
import { Category } from '../../models/api/category.model';
import { CustomResponse } from '../../models/api/error/custom-response.model';
import { Item } from '../../models/api/item.model';
import { ManageCatalog } from '../../models/api/manage-catalog.model';
import { UserLogin } from '../../models/api/user/user-login';
import { UserRegistration } from '../../models/api/user/user-registration.model';
import { UserResult } from '../../models/user/user-result.model';
import { User } from '../../models/user/user.model';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root'
})
export class ApiDataService {
  apiUrl = `${environment.apiUrl}/api`;

  constructor(private requestService: RequestService, private http: HttpClient) { }

  async getLastExceptionLog(): Promise<CustomResponse<any>> {
    return await this.requestService.makePromise<object>(Object,
      this.http.get<CustomResponse<any>>(`${this.apiUrl}/Exception/LastException`), false);
  }

  async register(userRegistration: UserRegistration): Promise<CustomResponse<UserResult>> {
    return await this.requestService.makePromise<UserResult>(UserResult,
      this.http.post<CustomResponse<UserResult>>(`${this.apiUrl}/User/Create`, userRegistration));
  }

  async login(userLogin: UserLogin): Promise<CustomResponse<UserResult>> {
    return await this.requestService.makePromise<UserResult>(UserResult,
      this.http.post(`${this.apiUrl}/User/Login`, userLogin));
  }

  async getUser(displayException: boolean = true): Promise<CustomResponse<User>> {
    return await this.requestService.makePromise<User>(User,
      this.http.get<User>(`${this.apiUrl}/User`), displayException);
  }

  async checkUserName(username: string) {
    return await this.requestService.makePromise<boolean>(null,
      this.http.get<User>(`${this.apiUrl}/User/UserNameExists?userName=${username}`));
  }

  getCatalogsAsync(): Observable<CustomResponse<Array<Catalog>>> {
    return this.requestService.makeObservable(Array<Catalog>,
      this.http.get<CustomResponse<Array<Catalog>>>(`${this.apiUrl}/Catalog/GetAll`));
  }

  getItemsAsync(): Observable<CustomResponse<Array<Item>>> {
    return this.requestService.makeObservable(Array<Item>,
      this.http.get<Array<Item>>(`${this.apiUrl}/Item/GetAll`));
  }

  getGetagoriesAsync(): Observable<CustomResponse<Array<Category>>> {
    return this.requestService.makeObservable(Array<Category>,
      this.http.get<CustomResponse<Array<Category>>>(`${this.apiUrl}/Category/GetAll`));
  }

  async addCatalog(catalog: ManageCatalog): Promise<CustomResponse<Catalog>> {
    return await this.requestService.makePromise<Catalog>(Catalog,
      this.http.post<CustomResponse<Catalog>>(`${this.apiUrl}/Catalog`, catalog));
  }

  async editCatalog(id: number, catalog: ManageCatalog): Promise<CustomResponse<Catalog>> {
    return await this.requestService.makePromise<Catalog>(Catalog,
      this.http.post<CustomResponse<Catalog>>(`${this.apiUrl}/Catalog?id=${id}`, catalog));
  }

  async deleteCatalogs(ids: Array<number>): Promise<CustomResponse<any>> {
    return await this.requestService.makePromise<any>(null,
      this.http.post<CustomResponse<any>>(`${this.apiUrl}/Catalog/DeleteMany`, ids));
  }

  getCatalogItemsAsync(catalogId: number): Observable<CustomResponse<Array<CatalogItem>>> {
    return this.requestService.makeObservable(Array<CatalogItem>,
      this.http.get<CustomResponse<Array<CatalogItem>>>(`${this.apiUrl}/CatalogItem/GetAll?id=${catalogId}`));
  }

  async updateCatalogItems(catalogId: number, itemIds: number[]): Promise<CustomResponse<Array<CatalogItem>>> {
    return await this.requestService.makePromise<Array<CatalogItem>>(Array<CatalogItem>,
      this.http.post<CustomResponse<Array<CatalogItem>>>(`${this.apiUrl}/CatalogItem/UpdateCatalogItems?id=${catalogId}`, itemIds));
  }

  async addItems(items: Array<Item>): Promise<CustomResponse<Array<Item>>> {
    return await this.requestService.makePromise<Array<Item>>(Array<Item>,
      this.http.post<CustomResponse<Array<Item>>>(`${this.apiUrl}/Item/AddItems`, items));
  }

  async getExceptions(): Promise<CustomResponse<Array<any>>> {
    return await this.requestService.makePromise<Array<string>>(Array<string>,
      this.http.get<Array<any>>(`${this.apiUrl}/Exception`));
  }
}
