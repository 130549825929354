import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, map } from 'rxjs';
import { CustomResponse } from '../../models/api/error/custom-response.model';
import { ErrorData } from '../../models/api/error/error-data.model';
import { UserLogin } from '../../models/api/user/user-login';
import { UserRegistration } from '../../models/api/user/user-registration.model';
import { RouterLinkEnum } from '../../models/enums/router-link.enum';
import { UserResult } from '../../models/user/user-result.model';
import { User } from '../../models/user/user.model';
import { createAndAssign } from '../../utility/common.utils';
import { SessionService } from '../session.service';
import { StateService } from '../state.service';
import { StorageService } from '../storage.service';
import { ToastService } from '../toast.service';
import { ApiDataService } from './api-data.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  user$: Observable<User> = new Observable<null>();
  loggedIn$: Observable<boolean>;

  constructor(private stateService: StateService,
    private apiDataService: ApiDataService,
    private storageService: StorageService,
    private sessionService: SessionService,
    private toastService: ToastService,
    private router: Router) {
    this.user$ = this.stateService.user$;

    this.loggedIn$ = this.user$.pipe(
      map((user: User) =>
        user && user.hasCredentials())
    );
  }

  async getUser(displayException: boolean = true) {
    if (!this.storageService.getJwt())
      return true;

    const userResponse = await this.apiDataService.getUser(displayException);
    this.updateUserSubject(createAndAssign(User, userResponse.data));

    if (userResponse.error)
      return false;

    return true;
  }

  async login(userLogin: UserLogin): Promise<ErrorData> {
    if (!userLogin.valid())
      return { message: 'UserName and Password are required', } as ErrorData;

    const userResultResponse = await this.apiDataService.login(userLogin);
    this.validUserResult(userResultResponse);

    return userResultResponse.error;
  }

  async logout() {
    this.validUserResult(null);
  }

  async register(userRegistration: UserRegistration): Promise<ErrorData> {
    if (!userRegistration.valid())
      return { message: 'UserName, Password and Email are required', } as ErrorData;

    const userResultResponse = await this.apiDataService.register(userRegistration);

    if (!this.validUserResult(userResultResponse)) {
      return userResultResponse.error;
    }

    return null;
  }

  private validUserResult(userResultResponse: CustomResponse<UserResult>) {
    const user = createAndAssign(User, userResultResponse?.data?.user);
    const token = userResultResponse?.data?.token;

    if (this.storageService.getJwt() && !token)
      this.toastService.showSuccess('You have been logged out');
    else if (!this.sessionService.gettUser() && user)
      this.toastService.showSuccess('You have been logged in');

    this.updateUserSubject(user);
    this.storageService.setJwt(token);

    if (!user) {
      return false;
    }

    this.router.navigate([RouterLinkEnum.Home]);
    return true;
  }

  private updateUserSubject(user: User) {
    this.sessionService.setUser(user);
    this.stateService.updateUserSubject(user);
  }
}
