<p-editor #editor #editorNgModel="ngModel" [(ngModel)]="value" [maxlength]="maxLength" (ngModelChange)="onModelChange()"
    [style]="{ height: '320px' }">
    <ng-template pTemplate="header">
        <div class="ql-toolbar">

            <!-- Font Group -->
            <select class="ql-font">
                <option value="sans-serif"></option>
                <option value="serif"></option>
                <option value="monospace"></option>
            </select>

            <!-- Size Group -->
            <select class="ql-size">
                <option value="small"></option>
                <option selected></option>
                <option value="large"></option>
                <option value="huge"></option>
            </select>

            <!-- Format Group -->
            <div class="toolbar-spacer"></div>
            <button type="button" class="ql-bold" aria-label="Bold"></button>
            <button type="button" class="ql-italic" aria-label="Italic"></button>
            <button type="button" class="ql-underline" aria-label="Underline"></button>
            <button type="button" class="ql-strike" aria-label="Strike"></button>

            <!-- List Group -->
            <div class="toolbar-spacer"></div>
            <button type="button" class="ql-list" value="ordered" aria-label="Ordered List"></button>
            <button type="button" class="ql-list" value="bullet" aria-label="Bullet List"></button>

            <!-- Indent Group -->
            <div class="toolbar-spacer"></div>
            <button type="button" class="ql-indent" value="-1" aria-label="Indent"></button>
            <button type="button" class="ql-indent" value="+1" aria-label="Outdent"></button>

            <!-- Align Group -->
            <div class="toolbar-spacer"></div>
            <button type="button" class="ql-align" value="" aria-label="Align Left"></button>
            <button type="button" class="ql-align" value="center" aria-label="Align Center"></button>
            <button type="button" class="ql-align" value="right" aria-label="Align Right"></button>
            <button type="button" class="ql-align" value="justify" aria-label="Justify"></button>

            <!-- Header Group -->
            <div class="toolbar-spacer"></div>
            <button type="button" class="ql-header" value="1" aria-label="Header 1"></button>
            <button type="button" class="ql-header" value="2" aria-label="Header 2"></button>

            <!-- Color Group -->
            <!-- <div class="toolbar-spacer"></div>
            <button type="button" class="ql-color" aria-label="Text Color"></button>
            <button type="button" class="ql-background" aria-label="Background Color"></button> -->

            <!-- Link, Image, Video Group -->
            <!-- <div class="toolbar-spacer"></div>
            <button type="button" class="ql-link" aria-label="Insert Link"></button>
            <button type="button" class="ql-image" aria-label="Insert Image"></button>
            <button type="button" class="ql-video" aria-label="Insert Video"></button> -->

            <!-- Clean Group -->
            <div class="toolbar-spacer"></div>
            <button type="button" class="ql-clean" aria-label="Remove Formatting"></button>
        </div>
    </ng-template>
</p-editor>