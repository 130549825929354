import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, NgForm, NgModel, } from '@angular/forms';
import { UserLogin } from '../../../models/api/user/user-login';
import { ControlValidationService } from '../../../services/control-validation.service';
import { UserService } from '../../../services/data/user.service';
import { ProgressService } from '../../../services/progress.service';
import { ToastService } from '../../../services/toast.service';
import { FormService } from '../../../services/form.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent implements OnInit {
  userLogin: UserLogin = new UserLogin;

  processing: boolean;

  constructor(private progressService: ProgressService,
    private userService: UserService,
    private toastService: ToastService,
    private formService: FormService,
    private controlValidationService: ControlValidationService) {
  }

  ngOnInit() {
  }

  async onLogin(form: any) {
    this.isProcessing(true);
    try {
      if (!this.userLogin.valid()) {        
        this.formService.markFormGroupDirty(form);
        this.toastService.showError('Login details are invalid');
        return;
      }

      const userLoginError = await this.userService.login(this.userLogin);

      if (userLoginError?.validationMessages)
        this.toastService.showError(
          userLoginError.validationMessages.joinForToast(), "Login failed");
    } finally {
      this.isProcessing(false);
    }
  }

  private isProcessing(value: boolean) {
    this.processing = value;
    this.progressService.onIsWaiting.emit(value);
  }

  isInvalid(ngModel: NgModel): boolean {
    return this.controlValidationService.isInvalid(ngModel);
  }
}
