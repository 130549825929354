import { Injectable } from '@angular/core';
import { AbstractControl, NgModel } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ControlValidationService {

  constructor() { }

  private getControl(control: AbstractControl | NgModel): AbstractControl {
    return control instanceof NgModel ? control.control : control;
  }

  getError(control: AbstractControl | NgModel, errorType: string): boolean {
    const ctrl = this.getControl(control);
    return ctrl && ctrl.errors && ctrl.errors[errorType];
  }

  isDirtyOrTouched(control: AbstractControl | NgModel): boolean {
    const ctrl = this.getControl(control);
    return ctrl ? ctrl.dirty || ctrl.touched : false;
  }

  isDirtyAndTouched(control: AbstractControl | NgModel): boolean {
    const ctrl = this.getControl(control);
    return ctrl ? ctrl.dirty && ctrl.touched : false;
  }

  isInvalid(control: AbstractControl | NgModel): boolean {
    const ctrl = this.getControl(control);
    return ctrl && ctrl.invalid && this.isDirtyOrTouched(ctrl);
  }

  isInvalidCustom(control: AbstractControl | NgModel, customError: string, isDirtyAndTouched: boolean): boolean {
    const ctrl = this.getControl(control);

    if (isDirtyAndTouched) {
      return ctrl && ctrl.errors && ctrl.errors[customError] && this.isDirtyAndTouched(ctrl);
    }

    return ctrl && ctrl.errors && ctrl.errors[customError];
  }

  isRequired(control: AbstractControl | NgModel): boolean {
    const ctrl = this.getControl(control);
    return ctrl && ctrl.errors && ctrl.errors['required'] && this.isDirtyOrTouched(ctrl);
  }

  isPending(control: AbstractControl | NgModel): boolean {
    const ctrl = this.getControl(control);
    return ctrl && ctrl.pending && this.isDirtyOrTouched(ctrl);
  }

  isMaxLength(control: AbstractControl | NgModel): boolean {
    const ctrl = this.getControl(control);
    return ctrl && ctrl.errors && ctrl.errors['maxlength'] && this.isDirtyOrTouched(ctrl);
  }

  getMaxLength(control: AbstractControl | NgModel): any {
    const ctrl = this.getControl(control);
    if (ctrl && ctrl.errors && ctrl.errors['maxlength']) {
      return ctrl.errors['maxlength']?.requiredLength;
    }

    return null;
  }

  getMaxLengthDisplay(control: AbstractControl | NgModel): any {
    const ctrl = this.getControl(control);
    if (ctrl && ctrl.errors && ctrl.errors['maxlength']) {
      return `${ctrl.errors['maxlength']?.actualLength} / ${ctrl.errors['maxlength']?.requiredLength}`;
    }

    return null;
  }

  isEmailInvalid(control: AbstractControl | NgModel): boolean {
    const ctrl = this.getControl(control);
    return ctrl && ctrl.errors && ctrl.errors['email'] && this.isDirtyAndTouched(ctrl);
  }

  isPasswordsDoNotMatch(passwordControl: AbstractControl | NgModel, confirmPasswordControl: AbstractControl | NgModel): boolean {
    const passwordCtrl = this.getControl(passwordControl);
    const confirmPasswordCtrl = this.getControl(confirmPasswordControl);

    return passwordCtrl && confirmPasswordCtrl
      && passwordCtrl.value !== confirmPasswordCtrl.value
      && this.isDirtyOrTouched(passwordCtrl)
      && this.isDirtyAndTouched(confirmPasswordCtrl);
  }
}
