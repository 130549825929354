import { Injectable } from '@angular/core';
import { filter, firstValueFrom, map, tap } from 'rxjs';
import { CatalogItem } from '../../models/api/catalog-item.model';
import { CustomResponse } from '../../models/api/error/custom-response.model';
import { Item } from '../../models/api/item.model';
import { ManageItems } from '../../models/api/manage-items.model';
import { StateType } from '../../models/enums/state-type.enum';
import { createAndAssignArray } from '../../utility/common.utils';
import { StateService } from '../state.service';
import { ApiDataService } from './api-data.service';
import { CatalogItemService } from './catalog-item.service';

@Injectable({
  providedIn: 'root'
})
export class ItemService {
  items$ = this.stateService.items$;

  constructor(private catalogItemService: CatalogItemService,
    private stateService: StateService,
    private apiDataService: ApiDataService) {
    // initial get of Catalogs
    this.getItems().subscribe();
  }

  /** Get Items from API and sets Processing subject */
  getItems() {
    this.stateService.setState(`${StateType.Items}${StateType.IsProcessing}`, true);
    return this.apiDataService.getItemsAsync().pipe(
      tap(async (catalogsResponse: CustomResponse<Item[]>) => {
        await this.updateItemsSubject(createAndAssignArray<Item>(Item, catalogsResponse.data), true);
        this.stateService.setState(`${StateType.Items}${StateType.IsSuccessful}`, catalogsResponse.isSuccessful);
        this.stateService.setState(`${StateType.Items}${StateType.IsProcessing}`, false);
      }),
      map(() => undefined)
    );
  }

  /** Update BehaviorSubject, receiving an Array of Items, passing reset = true will use the Items parameter only */
  private async updateItemsSubject(items: Item[], reset: boolean = false) {
    const existingItemsMap = new Map<number, Item>();

    if (!reset) {
      const existingItems = await firstValueFrom(
        this.items$.pipe(
          filter((items: Array<Item>) => items != null)
        )
      );
      
      existingItems.forEach(item => existingItemsMap.set(item.id, item));
    }

    items.forEach(item => existingItemsMap.set(item.id, item));

    const updatedItems = Array.from(existingItemsMap.values());
    this.stateService.updateItemsSubject(updatedItems);
  }

  /** Update Catalog Items and items$ (coming soon, better method name) */
  async addManageCatalogItems(catalogId: number, updateManageItems: ManageItems): Promise<boolean> {
    var newItemIds = await this.addItems(updateManageItems.items);
    
    var catalogItemIds = [...updateManageItems.itemIds, ...newItemIds];
    const catalogItems = await this.addCatalogItems(catalogId, catalogItemIds);

    let isSuccessful = newItemIds !== null && catalogItems != null;
    return isSuccessful;
  }

  /** Send new Items to api and return, updated Subject with differences */
  async addItems(newItems: Item[]) {
    const itemResponse = await this.apiDataService.addItems(newItems);
    const items = createAndAssignArray<Item>(Item, itemResponse.data);

    this.updateItemsSubject(items);

    const itemIds = items.map((item: Item) => item.id);
    return itemIds;
  }

  /** Send ItemIds to be added to an existing Catalog */
  async addCatalogItems(catalogId: number, newItemIds: number[]): Promise<CatalogItem[]> {
    const updateCatalogItemsResponse = await this.apiDataService.updateCatalogItems(catalogId, newItemIds);
    const itemIds = createAndAssignArray<CatalogItem>(CatalogItem, updateCatalogItemsResponse.data);
    return itemIds;
  }
}
