<p class="font-bold">Choose below to get started</p>
<div class="p-4">
    <div class="grid">
        <div class="sm:col-12 md:col-5">
            <app-login></app-login>
        </div>
        <div class="sm:col-12 md:col-7 md">
            <app-register></app-register>
        </div>
    </div>
</div>