import { Component, Input } from '@angular/core';
import { RouterLinkEnum } from '../../../../models/enums/router-link.enum';
import { User } from '../../../../models/user/user.model';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrl: './pages.component.scss'
})
export class PagesComponent {
  @Input() loggedIn: boolean;
  @Input() buttonType = 'mat-button'
  @Input() showName = true;
  
  RouterLinkEnum = RouterLinkEnum;
    
  constructor() {
  }
}
