import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, tap } from "rxjs";
import { CatalogItem } from "../../../models/api/catalog-item.model";
import { Item } from "../../../models/api/item.model";
import { ManageItems } from "../../../models/api/manage-items.model";
import { CatalogItemService } from "../../../services/data/catalog-item.service";
import { ItemService } from "../../../services/data/item.service";

@Injectable()
export class ManageItemsService {
  private _manageItemsSubject: BehaviorSubject<ManageItems> = new BehaviorSubject<ManageItems>(null);
  manageItems$: Observable<ManageItems> = this._manageItemsSubject.asObservable();

  private _isProcessingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isProcessing$: Observable<boolean> = this._isProcessingSubject.asObservable();

  constructor(private itemService: ItemService,
    private catalogItemService: CatalogItemService
  ) { }

  get manageItems() {
    return this._manageItemsSubject.getValue();
  }

  /** Creates Manage Items Behavor Subject */
  initManageItems(catalogId: number) {
    this.catalogItemService.getCatalogItems(catalogId)
      .pipe(
        tap((catalogItems: Array<CatalogItem>) => {
          const manageItems: ManageItems = {
            itemIds: catalogItems.map((catalogItem: CatalogItem) => catalogItem.itemId),
            items: []
          };
          this.updateCatalogItemsSubject(manageItems);
        })
      )
      .subscribe();
  }

  /** Add Item to ManageItems (new as Item, existing as ItemId */
  addItemToManageItems(itemToAdd: Item) {
    if (itemToAdd.id > 0) {
      this.manageItems.itemIds.push(itemToAdd.id);
    } else {
      this.manageItems.items.push(itemToAdd);
    }
  }

  /** Removes Item from ManageItems (new from 'itemIds', existing from 'items') */
  removeItemFromManageItems(itemToRemove: Item) {
    if (itemToRemove.id > 0) {
      const removeItemIndex = this.manageItems.itemIds.indexOf(itemToRemove.id);
      if (removeItemIndex !== -1) {
        this.manageItems.itemIds.splice(removeItemIndex, 1);
      }
    }

    this.removeItem(this.manageItems.items, itemToRemove);
  }

  /** Removes item from args Array<Item> */
  removeItem(items: Item[], itemToRemove: Item) {
    const sourceItemIndex = items.findIndex((item: Item) => item === itemToRemove);
    if (sourceItemIndex !== -1) {
      items.splice(sourceItemIndex, 1);
    }
  }

  updateCatalogItemsSubject(manageItems: ManageItems) {
    this._manageItemsSubject.next(manageItems);
  }

  /** Interacts with API to Update Catalog Items */
  async manageCatalogItems(catalogId: number) {
    let isSuccessful = false;

    if (catalogId > 0) {
      const catalogItemsIsSuccessful = await this.itemService.addManageCatalogItems(catalogId, this.manageItems);
      isSuccessful = catalogItemsIsSuccessful;
    }

    return isSuccessful;
  }
}
