import { Directive } from '@angular/core';
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';
import { ValidatorService } from '../../services/validator.service';

@Directive({
  selector: '[appDuplicateUserName]',
  providers: [
    { provide: NG_ASYNC_VALIDATORS, useExisting: DuplicateUserNameValidatorDirective, multi: true }
  ]
})
export class DuplicateUserNameValidatorDirective implements AsyncValidator {
  constructor(private validatorService: ValidatorService) {}

  validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return this.validatorService.duplicateUserName(control);
  }
}
