const DESCRIPTION_MAX_LENGTH = 64000;

export namespace Validation {
  export enum ManageCatalog {
    DescriptionMaxLength = DESCRIPTION_MAX_LENGTH
  }
  export enum ManageItem {
    DescriptionMaxLength = DESCRIPTION_MAX_LENGTH
  }
}
