import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Editor } from 'primeng/editor';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrl: './editor.component.scss'
})
export class EditorComponent implements AfterViewInit {
  @Input() value: string = '';
  @Input() maxLength: number;

  @Output() valueChange = new EventEmitter<string>();

  @ViewChild('editor') editor: Editor;
  @ViewChild('editorNgModel', { static: false }) editorNgModel: NgModel;

  events = ['click', 'paste', 'focus', 'blur', 'keyup'];

  editorNgModelReturn: any;

  constructor(private cd: ChangeDetectorRef) { }

  ngAfterViewInit() {
    this.events.forEach(event =>
      this.editor.el.nativeElement.addEventListener(event, this.onChanges.bind(this)));

    setTimeout(() => {
      this.editorNgModelReturn = this.editorNgModel;
    });
  }

  onModelChange() {
    this.valueChange.emit(this.value);
  }

  get getEditor() {
    return this.editorNgModelReturn;
  }

  onChanges() {
    this.cd.detectChanges();
  }
}
