<div class="flex flex-column h-full relative" [appProgress]="catalogIsProcessing$ | async" [text]="loadingCatalogText">
  <ng-container *ngIf="selectedCatalog$ | async as catalog">
    <div class="sticky z-2 bg-white glow-white" [style.top.px]="10">
      <div class="flex flex-row align-items-center">
        <app-page-heading class="flex-grow-1" [title]="catalog.name"></app-page-heading>
        <app-catalog-actions class="flex-grow-0" style="align-self: baseline;"></app-catalog-actions>
      </div>
    </div>
    <div class="p-2 flex">
      <app-show-more [(isExpanded)]="showMore" [inline]="true" [stickyTop]="false" [stickyPadding]="20" [text]="catalog.description" clampLines="10"></app-show-more>
    </div>
    <div class="flex-grow-1 col-12 pt-4 static"
      [appProgress]="(catalogItemsIsProcessing$ | async) && !(catalogIsProcessing$ | async)"
      [text]="loadingItemIdsText">
      <app-items [catalogId]="catalog.id" [itemIds]="catalogItemIds$ | async"></app-items>
    </div>
  </ng-container>
  &nbsp;
</div>