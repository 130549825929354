import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, firstValueFrom, map, of } from 'rxjs';
import { CustomResponse } from '../../models/api/error/custom-response.model';
import { ErrorData } from '../../models/api/error/error-data.model';
import { createAndAssign } from '../../utility/common.utils';
import { ToastService } from '../toast.service';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  constructor(private toastService: ToastService) { }

  makeObservable<T>(targetType: new () => T, request: Observable<any>, displayException: boolean = true): Observable<CustomResponse<T>> {
    return request.pipe(
      catchError((error: any) => {
        const processedErrorResponse = this.processError<T>(error, displayException);

        if (processedErrorResponse.isFatal) {
          console.error('FATAL', processedErrorResponse); // Investigate
        }

        return of(processedErrorResponse);
      }),
      map(apiResponse => {
        const apiResponseTyped = this.createAndAssignCustomResponse<T>(targetType, apiResponse);
        return apiResponseTyped;
      }),
    );
  }

  async makePromise<T>(targetType: new () => T, request: Observable<any>, displayException: boolean = true): Promise<CustomResponse<T>> {
    return await firstValueFrom(
      this.makeObservable<T>(targetType, request, displayException));
  }

  private createAndAssignCustomResponse<T>(targetType: new () => T, source: any): CustomResponse<T> {
    let customResponseInstance = new CustomResponse<T>();

    if (source) {
      customResponseInstance = createAndAssign<CustomResponse<T>>(CustomResponse, source);
    } else {
      customResponseInstance.isSuccessful = true
    }
    
    if (source && source.data) {
      if (targetType) {
        let instanceData: T = new targetType();
        instanceData = createAndAssign<T>(targetType, source.data);
        customResponseInstance.data = instanceData;
      } else {
        customResponseInstance.data = source.data;
      }
    }

    return customResponseInstance;
  }

  private processError<T>(errorResponse: HttpErrorResponse, displayException: boolean = true) {
    if (errorResponse.error) {
      let customResponseError = new CustomResponse<T>;
      customResponseError.isSuccessful = errorResponse.error.isSuccessful;
      customResponseError.error = createAndAssign(ErrorData,
        errorResponse?.error?.error?.entityData ? errorResponse.error.error.entityData :
          errorResponse?.error?.error ? errorResponse.error.error : errorResponse?.error?.data);

      if (customResponseError.isSuccessful !== undefined) {
        return customResponseError;
      }
    }

    var httpErrorResponse = new HttpErrorResponse(errorResponse);
    const message = `${httpErrorResponse.name}: ${httpErrorResponse.message}`;

    let fatalError = new CustomResponse<T>;
    fatalError.isFatal = true;
    fatalError.isSuccessful = httpErrorResponse.ok;
    fatalError.error = new ErrorData;
    fatalError.error.message = message;
    fatalError.error.endPoint = httpErrorResponse.url ?? '';
    fatalError.error.statusCode = httpErrorResponse.status;
    fatalError.error.statusCodeDescription = httpErrorResponse.statusText;

    if (displayException) {
      fatalError.error.isReported = true;
      this.toastService.showError(message, 'Unhandle Request error', 10000);
    }

    return fatalError;
  }
}
